import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Dispatch, SetStateAction } from 'react'
import { Row } from 'src/components/base'
import { VettingSessionStatusBadge } from 'src/components/VettingCampaignStatusBadge'
import { WorkerVettingSessionResponse } from 'src/hooks/useWorkerVetting'
import { WorkerVettingSessionMessage } from 'src/types/worker-vetting'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'
import { formatPhoneNumber } from 'src/utils/stringUtils'

interface WorkerVettingSessionMessagePreviewProps {
  vettingSession: WorkerVettingSessionResponse
  isSelected: boolean
  setSelectedSession: Dispatch<
    SetStateAction<WorkerVettingSessionResponse | undefined>
  >
}

const formatMessagePreview = (message: WorkerVettingSessionMessage) => {
  return `${message.source}: ${message.message.slice(0, 50)}${
    message.message.length > 50 ? '...' : ''
  }`
}

export default function WorkerVettingSessionMessagePreview(
  props: WorkerVettingSessionMessagePreviewProps,
) {
  const { vettingSession, isSelected, setSelectedSession } = props

  const messages = vettingSession.messages
  const mostRecentMessage =
    messages.length > 0 ? messages[messages.length - 1] : undefined
  return (
    <Row
      center
      style={{
        padding: theme.space.sm,
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.colors.Grey20}`,
        backgroundColor: isSelected ? theme.colors.Violet10 : 'transparent',
      }}
      onClick={() => setSelectedSession(vettingSession)}
      flexCol
    >
      <Row fullWidth justifyBetween>
        <Row>
          <Text variant="h6" style={{ marginRight: theme.space.xxs }}>
            {formatPhoneNumber(vettingSession.phoneNumber, true)}
          </Text>
          <VettingSessionStatusBadge status={vettingSession.status} />
        </Row>
        <Row>
          <Text variant="body2" mr={theme.space.xxxs}>
            {formatTimeDistanceToNow(new Date(vettingSession.lastActiveAt))}
          </Text>
        </Row>
      </Row>
      <Text variant="body1">
        {mostRecentMessage
          ? formatMessagePreview(mostRecentMessage)
          : 'No response from Worker yet'}
      </Text>
    </Row>
  )
}
