import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  AssignAccessLevelAndLocationsRow,
  Col,
  Text,
  SelectDropdown,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CompanyInvitation,
  USER_ROLE_OPTIONS,
  UserAccessLevel,
  UserRole,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { Input, Row } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { CreateInvitationParams } from 'src/hooks/useCompanyInvitations'
import { useRegionLocationMap } from 'src/hooks/useCompanyLocations'
import { useValidRegionsMap } from 'src/hooks/useRegions'

export type CreateInvitationModalProps = {
  onClose: () => void
  isOpen: boolean
  companyId: string
  createInvitationMutation: UseMutateAsyncFunction<
    CompanyInvitation,
    AxiosError<unknown, any>,
    CreateInvitationParams,
    unknown
  >
}

export const CreateInvitationModal = (props: CreateInvitationModalProps) => {
  const { hotSettings } = useHotSettings()
  const { showSuccess, showError } = useAlert()
  const { onClose, isOpen, createInvitationMutation, companyId } = props
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [email, setEmail] = useState<string | undefined>()
  const [userRole, setUserRole] = useState<UserRole>()
  const [userAccessLevel, setUserAccessLevel] = useState<UserAccessLevel>()
  const [assignedLocationIds, setAssignedLocationIds] = useState<Set<string>>(
    new Set(),
  )

  const { regionMap, isLoading: isLoadingRegionMap } = useValidRegionsMap()
  const {
    activeRegionsWithLocationsMap,
    isLoading: isLoadingRegionLocationMap,
  } = useRegionLocationMap(companyId)

  async function handleCreate(
    companyId: string,
    email: string,
    role: UserRole,
  ) {
    setConfirmLoading(true)
    try {
      await createInvitationMutation({
        companyId,
        email,
        role,
        userAccessLevel,
        locationIds: [...assignedLocationIds],
      })
      showSuccess(
        'Invitation was created successfully. The invited user will receive an email with a link to accept the invitation.',
        'Successfully sent invitation',
      )
    } catch (error) {
      showError(
        (error as Error).message ??
          'Error occurred during sending invitation. Please check your input and try again.',
        'Error creating invitation',
      )
    }
    setConfirmLoading(false)
    setEmail(undefined)
    setUserRole(undefined)
    onClose()
  }

  return (
    <Dialog
      dialogTitle="Send New Invitation"
      open={isOpen}
      onConfirmCTA="Confirm"
      confirmLoading={confirmLoading}
      onConfirm={async () => {
        if (!email || !userRole) {
          return
        }
        await handleCreate(companyId, email, userRole)
      }}
      confirmDisabled={
        !email ||
        !userRole ||
        (hotSettings?.enableRegionalAccessPhase2 && !userAccessLevel)
      }
      onClose={onClose}
    >
      <Col gap={theme.space.xs} width="100%">
        <Text variant="body2" mb={theme.space.xs}>
          Enter an email address and choose a role for the new member below and
          an invitation to join the company’s account will be sent.
        </Text>
        <Row gap={theme.space.sm}>
          <Input
            label="Email address"
            name="email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value)
            }}
            full
            containerStyle={{ flex: '2' }}
          />
          <SelectDropdown
            value={userRole || ''}
            menuItems={USER_ROLE_OPTIONS}
            handleSelect={(value) => setUserRole(value as UserRole)}
            label="Role"
            style={{ flex: '2' }}
          />
        </Row>

        {hotSettings?.enableRegionalAccessPhase2 && (
          <AssignAccessLevelAndLocationsRow
            userAccessLevel={userAccessLevel}
            setUserAccessLevel={setUserAccessLevel}
            assignedLocationIds={assignedLocationIds}
            setAssignedLocationIds={setAssignedLocationIds}
            regionMap={regionMap}
            regionToLocationsMap={activeRegionsWithLocationsMap}
            loading={isLoadingRegionMap || isLoadingRegionLocationMap}
          />
        )}
      </Col>
    </Dialog>
  )
}
