import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Card,
  LoadingSpinner,
  MultiDatePicker,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  ParentInvoiceGroup,
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  dateMinFromArray,
  getMinDateForScheduleChangeDatePicker,
  getShiftDatesForSchedules,
} from '@traba/utils'
import { isSameDay } from 'date-fns'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import { useModal } from 'src/components/base/Modal/Modal'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { useShiftRequestEditsMutation } from 'src/hooks/useShiftRequestEdits'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { RolesAndWorkersSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/RolesAndWorkersSection'
import { getShiftRequestForEditOrAdd } from 'src/screens/PostShiftScreen/components/PostShiftForm/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface Props {
  company: Company
  shifts?: Shift[]
  firstRecurringShiftRequest: ShiftRequest
  selectedShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onBack: () => void
  onSuccess: () => void
}

export const EditScheduleRoleDetails = ({
  company,
  firstRecurringShiftRequest,
  shiftRequestParent,
  selectedShiftRequest,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([getShiftRequestForEditOrAdd(shiftRequestParent, selectedShiftRequest)])
  const shiftsList = shifts?.filter(
    (s) =>
      s.shiftRequestId === firstRecurringShiftRequest.shiftRequestId && !!s.id,
  )
  const { companyId } = company
  const { roles, isLoading: isLoadingRoles } = useRoles({ companyId })

  const { showError, showSuccess } = useAlert()
  const { activeCompanyUsers, isLoading: isLoadingUsers } =
    useCompanyUsers(companyId)
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { hotSettings, isLoading: isLoadingHotsettings } = useHotSettings()
  const { refetch: refetchInvoiceGroups } = useInvoiceGroups(companyId)
  const createOrEditInvoiceGroupModal = useModal()
  const originalShiftRequest = getShiftRequestForEditOrAdd(
    shiftRequestParent,
    selectedShiftRequest,
  )
  const { editShiftRequest, isPending } = useShiftRequestEditsMutation(
    shiftRequestParent.shiftRequestParentId,
  )
  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequests((prev) => {
        return prev.map((shiftRequest) => {
          return {
            ...shiftRequest,
            parentInvoiceGroupId: newGroup.id,
          }
        })
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const minHourlyPayRate = useMemo(() => {
    return getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    })
  }, [company.minHourlyPayRate, hotSettings?.platformMinHourlyPayRate])

  if (
    isLoadingUsers ||
    isLoadingHotsettings ||
    isLoadingRoles ||
    isLoadingRosters
  ) {
    return <LoadingSpinner />
  }

  if (!shiftsList || shiftsList.length === 0) {
    return (
      <Text variant="error">
        There's no shifts in upcoming 3 weeks for this schedule and role
      </Text>
    )
  }

  const shiftTimes = getShiftDatesForSchedules(
    firstRecurringShiftRequest.schedules,
  )

  const onConfirmUpdates = async () => {
    try {
      if (!selectedDate) {
        return showError(
          'Please select a date that your change will take effect',
        )
      }
      const differences = _.omitBy(createShiftRequests[0], (value, key) =>
        // @ts-ignore - this is a hack to get the differences between the two objects
        _.isEqual(value, originalShiftRequest[key]),
      )
      await editShiftRequest({
        edit: {
          ...differences,
          editType: ShiftRequestEditType.ALL_FUTURE,
          shiftRequestId: selectedShiftRequest.shiftRequestId,
          originalStartTime: combineTwoDatesForDateAndTime(
            selectedDate,
            shiftsList[0].originalStartTime,
          ),
        },
      })
      onSuccess()
      showSuccess('Role edited successfully')
    } catch (error) {
      showError('Error updating shift request')
      console.error(error)
    }
  }

  const mapDaysForDatePicker = ({ date }: { date: DateObject }) => {
    const isUnavailable = !shiftTimes.some((shiftTime) =>
      isSameDay(date.toDate(), new Date(shiftTime)),
    )
    return {
      disabled: isUnavailable,
    }
  }

  return (
    <>
      <Text mb={theme.space.xs}>
        You are in edit mode for a schedule, shift type and schedule recurring
        pattern are not editable.
      </Text>
      <Card style={{ marginBottom: theme.space.sm }}>
        <Text variant="h5">
          Select when should your changes below start taking effect?
        </Text>
        <Text variant="body2" mb={theme.space.xs}>
          For example, if you want changes to take effect starting from
          Jun.30th, select Jun.30th
        </Text>

        <MultiDatePicker
          minDate={getMinDateForScheduleChangeDatePicker(
            combineTwoDatesForDateAndTime(
              new Date(),
              dateMinFromArray(
                firstRecurringShiftRequest.schedules.map((s) => s.startTime),
              ),
            ),
          )}
          selectedDates={selectedDate ? [selectedDate] : null}
          onSelectDates={(dates: Date[]) => {
            setSelectedDate(dates[0])
          }}
          multiple={false}
          mapDays={mapDaysForDatePicker}
        />
      </Card>
      {/* Uncomment this if we want to allow location change 
        <LocationSection
        setCreateShiftRequests={setCreateShiftRequests}
        createShiftRequests={createShiftRequests}
        locations={locations}
        timezone={shiftsList[0].timezone}
      /> */}
      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={roles}
        companyId={companyId}
        companyUsers={activeCompanyUsers}
        minHourlyPayRate={minHourlyPayRate}
        businessStartTime={shiftsList[0].businessStartTime ?? null}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
        isEditExistingRole
      />
      {/*  Uncomment this if we want to allow invoice group change
        <InvoiceSection
        createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        activeInvoiceGroups={activeInvoiceGroups}
      /> */}
      <Row alignCenter justifyBetween>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <Button onClick={onConfirmUpdates} loading={isPending}>
          Confirm updates
        </Button>
      </Row>
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
      />
    </>
  )
}
