import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { LocationMedia } from '@traba/types'
import { ExtendedShift } from '@traba/types'
import { TrainingVideo } from '@traba/types'
import { User } from '@traba/types'
import {
  Badge,
  Button,
  Col,
  CopyTextIcon,
  Icon,
  Image,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { getBadgeDetails } from 'src/screens/ToolsScreen/components/CustomizedBadge/BadgePreview'
import { getAddressString, getCompanyUsersNames } from 'src/utils/stringUtils'
import { SupervisorInfo } from './SupervisorInfo'

type DescriptionSectionProps = {
  companyUsers?: User[]
  shift: ExtendedShift
  handleClickEditLocation: (prePopulatedText?: string) => void
  handleClickImage: (url: string) => void
  handleClickEditTrainingVideo: () => void
  handleClickEditShiftBadge: () => void
}

export default function DescriptionSection(props: DescriptionSectionProps) {
  const {
    handleClickEditLocation,
    handleClickImage,
    shift,
    handleClickEditTrainingVideo,
    handleClickEditShiftBadge,
    companyUsers,
  } = props
  const { trainingVideos } = useTrainingVideos(shift.companyId)
  const { shiftBadges } = useCustomBadges({
    shiftId: shift.shiftId,
  })
  const locationImage = (img: LocationMedia, idx: number) => (
    <Image
      key={idx}
      src={img.imageUrl}
      alt={img.imageUrl}
      style={{
        borderRadius: '10px',
        cursor: 'pointer',
        marginRight: theme.space.xs,
        maxWidth: '150px',
        maxHeight: '150px',
      }}
      onClick={() => handleClickImage(img.imageUrl)}
    />
  )

  const opsLocationDetails =
    shift.opsLocationDetailsOverride ?? shift.location.opsLocationDetails
  const additionalDetailsText = opsLocationDetails ? (
    <>
      <Text>{opsLocationDetails}</Text>
      <CopyTextIcon textToCopy={opsLocationDetails} />
    </>
  ) : (
    <Text variant="body2">Add any additional instructions...</Text>
  )

  const getTrainingVideoText = (
    trainingVideos: TrainingVideo[] | undefined,
    videoIds: string[] | undefined,
  ) => {
    if (
      !trainingVideos ||
      !videoIds ||
      trainingVideos.length === 0 ||
      videoIds.length === 0
    ) {
      return 'None'
    }
    const trainingVideoText: string[] = []
    trainingVideos.forEach((tv) => {
      if (videoIds.includes(tv.id)) {
        trainingVideoText.push(tv.name)
      }
    })
    return trainingVideoText.join(', ')
  }

  const shiftSupervisor = companyUsers?.find(
    (user) => user.uid === shift.supervisorId,
  )

  return (
    <>
      <Row mb={theme.space.xxs}>
        <Col mr={24}>
          <Text variant="h7">Role</Text>
          <Text>{shift.roleDescription}</Text>
        </Col>
        <Col mr={36}>
          <Text variant="h7">Attire</Text>
          <Text>{shift.attireDescription}</Text>
        </Col>
      </Row>

      <Row mb={theme.space.xxs}>
        <Col mr={24}>
          <Row>
            <Text variant="h7">Shift Location</Text>
            {!shift.showAddressToNonWorkersForShift && (
              <InfoTooltip
                title={`Address shown to non-workers: ${
                  shift.location.neighborhoodName ??
                  shift.location.shortLocation
                }`}
              />
            )}
          </Row>
          {shift.location.name && <Text>{shift.location.name}</Text>}
          <Text>{getAddressString(shift.location.address) ?? '-'}</Text>
          <Text mt={theme.space.xxs}>
            {shift.location?.locationInstructions ?? '-'}
          </Text>
          <div style={{ marginTop: theme.space.xxs }}>
            {shift.location?.media?.map((img, idx) => locationImage(img, idx))}
          </div>
        </Col>
        <Col mr={36}>
          <Text variant="h7">Parking Location</Text>
          {shift.parkingLocation?.name && (
            <Text>{shift.parkingLocation?.name}</Text>
          )}
          <Text>
            {shift.parkingLocation?.address
              ? getAddressString(shift.parkingLocation?.address)
              : 'Same as work site location'}
          </Text>
          <Text mt={theme.space.xxs}>
            {shift.parkingLocation?.locationInstructions ?? '-'}
          </Text>
          <div style={{ marginTop: theme.space.xxs }}>
            {shift.parkingLocation?.media?.map((img, idx) =>
              locationImage(img, idx),
            )}
          </div>
        </Col>
      </Row>

      <Row mb={theme.space.xxs}>
        <Col>
          <Text variant="h7">Additional Details</Text>
          <Row>
            {additionalDetailsText}
            <Button
              style={{ padding: 0, marginLeft: '5px' }}
              variant={ButtonVariant.TRANSPARENT}
              onClick={() => handleClickEditLocation(opsLocationDetails)}
            >
              <Icon name="edit_active" width={18} />
            </Button>
          </Row>
        </Col>
        <Col>
          <Text variant="h7">Training Videos</Text>
          <Row>
            <Text>{getTrainingVideoText(trainingVideos, shift.videoIds)}</Text>
            {trainingVideos && trainingVideos?.length > 0 && (
              <Button
                style={{ padding: 0, marginLeft: '5px' }}
                variant={ButtonVariant.TRANSPARENT}
                onClick={() => handleClickEditTrainingVideo()}
              >
                <Icon name="edit_active" width={18} />
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      {!!shiftSupervisor && (
        <Row mb={theme.space.xxs}>
          <Col>
            <Text variant="h7">Shift Supervisor</Text>
            <SupervisorInfo supervisor={shiftSupervisor} />
          </Col>
        </Row>
      )}

      <Row mb={theme.space.xxs}>
        <Col>
          <Text variant="h7">Members receiving shift codes</Text>
          <Text>
            {getCompanyUsersNames(companyUsers, shift.shiftCodesReceiverIds)}{' '}
          </Text>
        </Col>
        <Col>
          <Text variant="h7">Shift badges</Text>

          <Row alignCenter gap={theme.space.xs}>
            {shiftBadges && shiftBadges.length > 0 ? (
              shiftBadges?.map((badge) => {
                const { variant, badgeName, id } = badge
                const { badgeStyleVariant } = getBadgeDetails(variant)
                return (
                  <Badge
                    key={id}
                    variant={badgeStyleVariant}
                    title={badgeName}
                  />
                )
              })
            ) : (
              <Text variant="body2">None</Text>
            )}
            <Button
              style={{ padding: 0, marginLeft: '5px' }}
              variant={ButtonVariant.TRANSPARENT}
              onClick={() => handleClickEditShiftBadge()}
            >
              <Icon name="edit_active" width={18} />
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  )
}
