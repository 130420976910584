import { useAlert } from '@traba/context'
import { SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ConnectionType } from '@traba/types'
import { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import {
  Button,
  CopyTextIcon,
  IconButton,
  Link,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { IMenuItem } from 'src/components/base/Select/Select'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import {
  deleteConnection,
  useWorkerConnections,
} from 'src/hooks/useConnections'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { truncateString } from 'src/utils/stringUtils'
import { WorkerConnectionModal } from './WorkerConnectionModal'

interface WorkerConnectionsProps {
  workerId: string
}

export default function WorkerConnections({
  workerId,
}: WorkerConnectionsProps) {
  const connectionOptions: IMenuItem[] = Object.keys(ConnectionType).map(
    (connectionType) => {
      return { label: connectionType, value: connectionType }
    },
  )
  const [connectionFilters, setConnectionFilters] =
    useState<IMenuItem[]>(connectionOptions)

  const { connections, isLoading, refetch } = useWorkerConnections(workerId)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { showSuccess, showError } = useAlert()
  const createWorkerConnectionModal = useModal()

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  const filteredConnections = connections?.filter((connection) => {
    return connectionFilters.some((filter) => {
      return filter.value === connection.connectionType
    })
  })
  const handleDeleteConnection = async (
    workerId: string,
    companyId: string,
    companyName: string,
  ) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this connection with ${companyName}?`,
      )
      if (!isConfirmed) {
        return
      }
      setDeleteLoading(true)
      await deleteConnection(workerId, companyId)
      showSuccess('Connection deleted successfully')
      refetch()
    } catch (error) {
      showError(getErrorMessage(error), 'Error deleting connection')
    } finally {
      setDeleteLoading(false)
    }
  }

  const tableRows: TableRow[] =
    filteredConnections?.map((connection) => {
      const {
        id,
        companyId,
        connectionReason = '',
        connectionReasons = [],
        employerName,
        connectionType,
        source,
        initiatedBy,
        note,
        updatedAt,
      } = connection

      const connReason = connectionReasons.length
        ? connectionReasons.join(', ')
        : connectionReason

      const connectionDate = new Date(updatedAt)

      return {
        key: id,
        cells: [
          {
            renderFn: () => (
              <Row justifyBetween>
                <Link to={`/companies/${companyId}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {employerName}
                  </Button>
                </Link>
              </Row>
            ),
            sortKey: employerName,
          },
          {
            renderFn: () => (
              <>
                {truncateString(companyId, 6)}
                <CopyTextIcon textToCopy={companyId} />
              </>
            ),
            sortKey: companyId,
          },
          {
            renderFn: () => connectionType,
            sortKey: connectionType,
          },
          {
            renderFn: () => source,
            sortKey: source,
          },

          {
            renderFn: () => initiatedBy,
            sortKey: initiatedBy,
          },
          {
            renderFn: () => connReason,
            sortKey: connReason,
          },
          {
            renderFn: () => note,
            sortKey: note,
          },
          {
            renderFn: () => formatDateTimeWithTimezone(connectionDate),
            sortKey: Date.parse(updatedAt),
          },
          {
            renderFn: () => (
              <IconButton
                iconName="trash"
                onClick={() =>
                  handleDeleteConnection(workerId, companyId, employerName)
                }
                loading={deleteLoading}
              />
            ),
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Row pb={theme.space.xs} fullWidth justifyBetween>
        <SearchSelect
          multiple
          onlyShowLabel
          options={connectionOptions}
          selectedItems={connectionFilters}
          handleSelectMultiple={setConnectionFilters}
          label="Connection Type"
        />
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={createWorkerConnectionModal.open}
        >
          Create Connection
        </Button>
      </Row>
      <DataTable
        initialSortByColumnIndex={0} // sort by employerName column
        rows={tableRows}
        headers={[
          {
            key: 'employerName',
            label: 'Company Name',
            sortable: true,
          },
          {
            key: 'companyId',
            label: 'Company ID',
            sortable: true,
          },
          {
            key: 'connectionType',
            label: 'Connection Type',
            sortable: true,
          },
          {
            key: 'source',
            label: 'Source',
            sortable: true,
          },
          {
            key: 'initiatedBy',
            label: 'Initiated By',
            sortable: true,
          },
          {
            key: 'connReason',
            label: 'Connection Reason',
            sortable: true,
          },
          {
            key: 'note',
            label: 'Note',
            sortable: true,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            sortable: true,
          },
          {
            key: 'deleteConnection',
            label: 'Delete',
            sortable: false,
          },
        ]}
      />
      <WorkerConnectionModal
        workerId={workerId}
        isOpen={createWorkerConnectionModal.isOpen}
        handleClose={createWorkerConnectionModal.handleClose}
      />
    </>
  )
}
