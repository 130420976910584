import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExtendedShift, InvoiceStatus } from '@traba/types'
import { useState } from 'react'
import {
  Button,
  Badge,
  CopyTextIcon,
  Link,
  Icon,
  Row,
  ShiftStatusBadge,
} from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import { getShiftInfoString, getTagEmojiString } from 'src/utils/shiftUtils'
import {
  getAddressString,
  getShortUnitDescription,
  truncateString,
} from 'src/utils/stringUtils'
import { EmploymentTypeBadge } from '../base/Badge/EmploymentTypeBadge'
import { ButtonVariant } from '../base/Button/types'
import { EarlyArrivalBufferBadge } from '../CollapsibleShiftRow/components/EarlyArrivalBufferBadge'
import { CompanyLogo } from '../CompanyLogo/CompanyLogo'
import { CompanyNoteDrawer } from '../CompanyNoteDrawer'
import { CompanyHeaderWrapper } from './CompanyHeader.styles'

export interface CompanyHeaderProps {
  shift: ExtendedShift
}

export const CompanyHeader = (props: CompanyHeaderProps) => {
  const { shift } = props
  const { state } = useUserContext()
  const [companyNotesOpen, setCompanyNotesOpen] = useState<boolean>(false)
  const tz = useTimezonedDates(shift.timezone)

  const dateString = tz.getShiftDate(shift.startTime, shift.endTime)
  const timeString = tz.getShiftTime(shift.startTime, shift.endTime)
  const addressString = getAddressString(shift.location.address)
  const { workerShifts = [], isLoading: workerShiftsLoading } = useWorkerShifts(
    shift?.id,
  )
  const shiftInvoicingIssueBadge = () => {
    const invoiceInProgress = !(
      shift.invoiceStatus &&
      [InvoiceStatus.OPEN, InvoiceStatus.PAID].includes(shift.invoiceStatus)
    )
    if (!shift.invoiceRequiresManualAdjustment || !invoiceInProgress) {
      return
    }

    return (
      <Row ml={36} mt={25}>
        <Badge title="requires invoice adjustment" variant="danger" />
      </Row>
    )
  }

  /*
   * Determine if shift has a buffer
   */
  const earlyArrivalBuffer = shift
    ? getEarlyArrivalTimeBufferInMinutes({
        shiftStartTime: shift.startTime,
        businessStartTime: shift.businessStartTime,
      })
    : undefined

  return (
    <>
      <CompanyHeaderWrapper email={state.userProfile?.email || ''}>
        <Row pt={150} alignCenter>
          <Link to={`/companies/${shift.companyId}`} target="_blank">
            <CompanyLogo
              style={{
                fontSize: '40px',
              }}
              companyName={shift.employerName}
              companyLogoUrl={shift.companyLogo}
            />
          </Link>
          <Row flexCol ml={24} mt={25}>
            <Text variant="h4">
              {shift.shiftRole}{' '}
              <Text variant="brand">
                (${shift.payRate}/{getShortUnitDescription(shift.payType)})
              </Text>
            </Text>
            <Row>
              <Link to={`/companies/${shift.companyId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {shift.employerName}
                </Button>
              </Link>
              <Text variant="caption" style={{ marginLeft: 12 }}>
                {truncateString(shift.companyId)}
                <CopyTextIcon textToCopy={shift.companyId} />
              </Text>
              <Button
                variant={ButtonVariant.OUTLINED}
                style={{
                  padding: theme.space.xxs,
                  height: theme.space.sm,
                  marginLeft: theme.space.xxs,
                }}
                onClick={() => setCompanyNotesOpen(!companyNotesOpen)}
              >
                <Row center>
                  <Icon name="info" />
                  <Text variant="caption">Notes</Text>
                </Row>
              </Button>
            </Row>
          </Row>
          <Row flexCol ml={36} mt={25}>
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="calendar_active"
                style={{ height: '17px', marginLeft: 2, marginRight: 6 }}
              />
              {`${dateString},  ${timeString}`}
            </Text>
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="location"
                style={{ height: '20px', marginRight: 4 }}
              />
              {addressString}
            </Text>
          </Row>
          <Row ml={36} mt={25} gap={theme.space.xxs}>
            <ShiftStatusBadge shiftStatus={shift.status} />
            {!!shift.shiftEmploymentType && (
              <EmploymentTypeBadge
                employmentTypes={[shift.shiftEmploymentType]}
              />
            )}
            {!!earlyArrivalBuffer && (
              <EarlyArrivalBufferBadge buffer={earlyArrivalBuffer} />
            )}
            {workerShiftsLoading ? (
              <CircularProgress size={theme.space.sm} />
            ) : (
              <Text variant="caption" style={{ marginLeft: 10 }}>
                {'Copy Shift Info'}
                <CopyTextIcon
                  textToCopy={getShiftInfoString(shift, workerShifts)}
                />
              </Text>
            )}
          </Row>
          {shiftInvoicingIssueBadge()}
          <Row ml={36} mt={22}>
            {getTagEmojiString(shift.tags || [])}
          </Row>
        </Row>
      </CompanyHeaderWrapper>
      <CompanyNoteDrawer
        companyId={shift.companyId}
        isOpen={companyNotesOpen}
        setIsOpen={setCompanyNotesOpen}
        hideFAB
      />
    </>
  )
}
