export enum ReportGroupBy {
  WORKER = 'WORKER',
  WORKER_SHIFT = 'WORKER_SHIFT',
  WORKER_AND_WORKER_SHIFT = 'WORKER_AND_WORKER_SHIFT',
  COST_CENTER = 'COST_CENTER',
  COST_CENTER_AND_WORKER = 'COST_CENTER_AND_WORKER',
}

export enum ReportColumnKey {
  WORKER_FULL_NAME = 'WORKER_FULL_NAME',
  WORKER_FIRST_NAME = 'WORKER_FIRST_NAME',
  WORKER_LAST_NAME = 'WORKER_LAST_NAME',
  ROLE_NAME = 'ROLE_NAME',
  LOCATION_NAME = 'LOCATION_NAME',
  CLOCK_IN_DATETIME = 'CLOCK_IN_DATETIME',
  CLOCK_OUT_DATETIME = 'CLOCK_OUT_DATETIME',
  REGULAR_HOURS_WORKED = 'REGULAR_HOURS_WORKED',
  OVERTIME_HOURS_WORKED = 'OVERTIME_HOURS_WORKED',
  BREAK_HOURS_TOTAL = 'BREAK_HOURS_TOTAL',
  TOTAL_HOURS_BILLED = 'TOTAL_HOURS_BILLED',
  GROSS_PAY_RATE = 'GROSS_PAY_RATE',
  REGULAR_MARKUP_RATE = 'REGULAR_MARKUP_RATE',
  REGULAR_BILL_RATE = 'REGULAR_BILL_RATE',
  REGULAR_AMOUNT_BILLED_TOTAL = 'REGULAR_AMOUNT_BILLED_TOTAL',
  OVERTIME_MARKUP_RATE = 'OVERTIME_MARKUP_RATE',
  OVERTIME_BILL_RATE = 'OVERTIME_BILL_RATE',
  OVERTIME_TYPE = 'OVERTIME_TYPE',
  OVERTIME_AMOUNT_BILLED_TOTAL = 'OVERTIME_AMOUNT_BILLED_TOTAL',
  TOTAL_BILL_RATE = 'TOTAL_BILL_RATE',
  TOTAL_AMOUNT_BILLED = 'TOTAL_AMOUNT_BILLED',
  INVOICE_GROUP_NAME = 'INVOICE_GROUP_NAME',
  COST_CENTER = 'COST_CENTER',
}

export type ReportColumn = {
  columnKey?: ReportColumnKey
  displayName?: string
}

export interface ReportConfiguration {
  groupBy: ReportGroupBy
  columns: ReportColumn[]
  showTotals?: boolean
  displayName?: string
}

export interface InvoiceStatementConfiguration extends ReportConfiguration {
  id: string
}

export type UpdateReportConfiguration =
  | Partial<ReportConfiguration>
  | InvoiceStatementConfiguration
