import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Dispatch, SetStateAction } from 'react'
import { EditScheduleOption } from './EditScheduleOption'

interface Props {
  selectedOption?: EDIT_OPTIONS
  setSelectedOption: Dispatch<SetStateAction<EDIT_OPTIONS | undefined>>
}

export enum EDIT_OPTIONS {
  EXTEND_SCHEDULE_OR_MODIFY_END_DATE = 'Extend Schedule or Modify End Date',
  MODIFY_AN_EXISTING_ROLE = 'Modify an Existing Role',
  ADD_A_NEW_ROLE = 'Add a New Role',
  REMOVE_ROLES = 'Remove Roles',
  CANCEL_SCHEDULE = 'Cancel Schedule',
}

export const EditScheduleEntry = (props: Props) => {
  const { selectedOption, setSelectedOption } = props
  const onClickOption = (option: EDIT_OPTIONS) => {
    setSelectedOption(option)
  }
  return (
    <>
      <Text variant="h5" mb={theme.space.xs}>
        What actions you want to take?
      </Text>
      <Col gap={theme.space.sm}>
        <EditScheduleOption
          onClick={() =>
            onClickOption(EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE)
          }
          selected={
            selectedOption === EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE
          }
          title="Extend schedule or modify schedule end date"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.MODIFY_AN_EXISTING_ROLE)}
          selected={selectedOption === EDIT_OPTIONS.MODIFY_AN_EXISTING_ROLE}
          title="Modify an existing role"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.ADD_A_NEW_ROLE)}
          selected={selectedOption === EDIT_OPTIONS.ADD_A_NEW_ROLE}
          title="Add a new role to this schedule"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.REMOVE_ROLES)}
          selected={selectedOption === EDIT_OPTIONS.REMOVE_ROLES}
          title="Remove roles from this schedule"
        />
        <EditScheduleOption
          onClick={() => onClickOption(EDIT_OPTIONS.CANCEL_SCHEDULE)}
          selected={selectedOption === EDIT_OPTIONS.CANCEL_SCHEDULE}
          title="Cancel this schedule"
        />
      </Col>
    </>
  )
}
