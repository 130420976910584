import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import Badge from '.'

const employmentTypeToBadgeVariantDict: Record<EmploymentType, string> = {
  [EmploymentType.W2]: 'warning',
  [EmploymentType.CONTRACTOR_1099]: 'success',
}

export type EmploymentTypeBadgeProps = {
  employmentTypes: EmploymentType[]
}

export function EmploymentTypeBadge({
  employmentTypes,
}: EmploymentTypeBadgeProps) {
  const renderedEmploymentTypes = employmentTypes.filter(
    (et) => et !== EmploymentType.CONTRACTOR_1099,
  )

  if (renderedEmploymentTypes.length === 0) {
    return undefined
  }

  return (
    <>
      {renderedEmploymentTypes.map((et) => (
        <Row mr={theme.space.xxxs} key={et}>
          <Badge
            title={et?.replaceAll('_', ' ')}
            variant={employmentTypeToBadgeVariantDict[et]}
          />
        </Row>
      ))}
    </>
  )
}
