import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Link } from 'react-router-dom'
import { Button, Col, CopyTextIcon, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DotText } from 'src/components/base/DotText'
import { PageHeader } from 'src/components/base/PageHeader'
import { SortOrder } from 'src/hooks/usePagination'
import { useSearchShifts } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import {
  getAddressString,
  getShortUnitDescription,
  truncateString,
} from 'src/utils/stringUtils'

interface ShiftCompanyHeaderProps {
  shiftId: string
}

export function ShiftInfoDetailsHeader({ shiftId }: ShiftCompanyHeaderProps) {
  const { shifts, isLoading: isLoadingShifts } = useSearchShifts({
    params: { id: shiftId },
    paginationParams: { sortBy: 'startTime', sortOrder: SortOrder.asc },
    select: {
      shift: [
        'id',
        'status',
        'createdAt',
        'startTime',
        'endTime',
        'regionId',
        'companyId',
        'timezone',
        'canceledAt',
        'slotsRequested',
        'slotsFilled',
        'overbookSlotsRequested',
        'shiftRequestId',
        'payType',
        'payRate',
      ],
      company: ['id', 'employerName', 'companyLogo'],
      role: ['roleName'],
      location: ['shortLocation', 'address'],
    },
  })
  const tz = useTimezonedDates(shifts[0]?.timezone || 'America/New_York')

  if (isLoadingShifts) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="large" />
      </Row>
    )
  }
  if (!shifts || shifts.length === 0) {
    return <div>Shift not found</div>
  }
  const shift = shifts[0]

  const dateString = tz.getShiftDate(shift.startTime, shift.endTime)
  const weekdayString = tz.getDayOfWeek(shift.startTime)
  const timeString = tz.getShiftTime(shift.startTime, shift.endTime)
  const addressString = getAddressString(shift.location.address)

  return (
    <PageHeader
      avatarString={shift.company.employerName}
      avatarUrl={shift.company.companyLogo}
      children={
        <Col ml={theme.space.xs}>
          <Row alignCenter>
            <Text variant="h4">{shift.role?.roleName}</Text>
            <Text variant="brand" style={{ marginLeft: theme.space.xs }}>
              (${shift.payRate}/{getShortUnitDescription(shift.payType)})
            </Text>
          </Row>
          <Row alignCenter>
            <Row mr={theme.space.xs} alignCenter>
              <Text variant="h7">Shift Id: {shift.id}</Text>
              <CopyTextIcon textToCopy={shift.id} />
            </Row>
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="calendar_active"
                style={{ height: '17px', marginRight: theme.space.xxxs }}
              />
              {`${weekdayString} ${dateString},  ${timeString}`}
            </Text>
            <DotText />
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="location"
                style={{ height: '20px', marginRight: theme.space.xxxs }}
              />
              {addressString}
            </Text>
          </Row>
          <Row alignCenter>
            <Link to={`/companies/${shift.companyId}`} target="_blank">
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {shift.company.employerName}
              </Button>
            </Link>
            <Text variant="caption" style={{ marginLeft: theme.space.xs }}>
              Company Id: {truncateString(shift.companyId)}
              <CopyTextIcon textToCopy={shift.companyId} />
            </Text>
          </Row>
        </Col>
      }
    />
  )
}
