import { StatsigProvider } from '@statsig/react-bindings'
import { useHotSettings, useInternalUsers } from '@traba/hooks'
import { useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSentinelContext } from 'src/context/sentinelContext/SentinelContext'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { WebSocketContext } from 'src/context/webSocketContext/WebSocketContext'
import { usePermissions } from 'src/hooks/usePermissions'
import { useSentinelNotificationToast } from 'src/hooks/useSentinelNotifications'
import {
  OPS_WEBSOCKET_KEY,
  PORTER_WEBSOCKET_KEY,
  WEBSOCKET_KEY,
  useWebSocketRoom,
} from 'src/hooks/useWebSockets'
import ActionCenter from 'src/screens/ActionCenter/ActionCenterScreen'
import CompanyLogsScreen from 'src/screens/AuditLogsScreen/CompanyLogsScreen'
import ShiftLogsScreen from 'src/screens/AuditLogsScreen/ShiftLogsScreen'
import WorkerLogsScreen from 'src/screens/AuditLogsScreen/WorkerLogsScreen'
import BillingScreen from 'src/screens/BillingScreen/BillingScreen'
import InvoicesCreationScreen from 'src/screens/BillingScreen/InvoiceCreationScreen/InvoiceCreationScreen'
import InvoicesDetailsScreen from 'src/screens/BillingScreen/InvoicesDetailsScreen/InvoicesDetailsScreen'
import CompanyDetailsScreen from 'src/screens/CompanyDetailsScreen/CompanyDetailsScreen'
import { CreateVettingCampaignScreen } from 'src/screens/CompanyDetailsScreen/components/CreateVettingCampaignScreen'
import { VettingCampaignSearchScreen } from 'src/screens/CompanyDetailsScreen/components/VettingCampaignSearchScreen'
import CompanySearchScreen from 'src/screens/CompanySearchScreen/CompanySearchScreen'
import DemoAccountScreen from 'src/screens/DemoAccountScreen'
import { FieldMonitorScreen } from 'src/screens/FieldMonitorScreen'
import InvoiceSettingsScreen from 'src/screens/InvoiceSettingsScreen/InvoiceSettingsScreen'
import { PostShiftScreen } from 'src/screens/PostShiftScreen/PostShiftScreen'
import AddOneOffToScheduleScreen from 'src/screens/ScheduleScreen/AddOneOffToScheduleScreen'
import ScheduleScreen from 'src/screens/ScheduleScreen/ScheduleScreen'
import ShiftDetailsScreen from 'src/screens/ShiftDetailsScreen/ShiftDetailsScreen'
import TimesheetScreen from 'src/screens/TimesheetScreen/TimesheetScreen'
import { ToolsScreen } from 'src/screens/ToolsScreen/ToolsScreen'
import WorkerDetailsScreen from 'src/screens/WorkerDetailsScreen/WorkerDetailsScreen'
import VettingCampaignDetailsScreen from 'src/screens/WorkerVettingScreen/VettingCampaignDetailsScreen'
import { createSentinelNotificationHandlers } from 'src/utils/sentinelNotificationUtils'
import CompanyWorkerFeedbackScreen from '../screens/CompanyWorkerFeedbackScreen/CompanyWorkerFeedbackScreen'
import CreateIncentive from '../screens/IncentivesMonitorScreen/CreateIncentive'
import IncentiveDetailsScreen from '../screens/IncentivesMonitorScreen/IncentiveDetailsScreen'
import IncentivesMonitor from '../screens/IncentivesMonitorScreen/IncentivesMonitor'
import { MessageLogsScreen } from '../screens/MessagesLogScreen/MessagesLogScreen'
import RegionsScreen from '../screens/RegionsScreen/RegionsScreen'
import WorkerSearchScreen from '../screens/WorkerSearchScreen/WorkerSearchScreen'
import CreateWorkerVettingCampaign from '../screens/WorkerVettingScreen/CreateWorkerVettingCampaign'
import WorkerVettingCampaignList from '../screens/WorkerVettingScreen/WorkerVettingCampaignList'

function LoggedIn() {
  const actionCenterAccess = usePermissions([
    UserRolePermission.ViewActionCenter,
  ])
  const { hotSettings } = useHotSettings()
  const { state, dispatch } = useUserContext()
  const { internalUsers, refetchInternalUsers } = useInternalUsers({
    emails: [state.userProfile?.email ?? ''],
    enabled: !!state.userProfile?.email,
  })
  useEffect(() => {
    const currentInternalUser = internalUsers?.find(
      (user) => user.email === state.userProfile?.email,
    )
    if (currentInternalUser) {
      dispatch({
        type: 'EDIT_USER',
        value: { internalUser: currentInternalUser },
      })
    }
  }, [internalUsers, dispatch, state.userProfile?.email])

  useEffect(() => {
    refetchInternalUsers()
  }, [refetchInternalUsers, state.userProfile?.email])

  const { connect } = useContext(WebSocketContext)

  const queryClient = useQueryClient()
  const { dispatch: sentinelDispatch } = useSentinelContext()
  const showNewSentinelNotificationToast = useSentinelNotificationToast()

  const sentinelHandlers = createSentinelNotificationHandlers(
    queryClient,
    sentinelDispatch,
    showNewSentinelNotificationToast,
  )

  useEffect(() => {
    connect(WEBSOCKET_KEY, import.meta.env.VITE_WEBSOCKET_URL)

    // Only create second socket in prod or when env vars are different
    if (
      import.meta.env.VITE_WEBSOCKET_URL !==
      import.meta.env.VITE_WEBSOCKET_OPS_URL
    ) {
      connect(OPS_WEBSOCKET_KEY, import.meta.env.VITE_WEBSOCKET_OPS_URL)
    }
    if (
      import.meta.env.VITE_WEBSOCKET_URL !==
      import.meta.env.VITE_WEBSOCKET_PORTER_URL
    ) {
      connect(PORTER_WEBSOCKET_KEY, import.meta.env.VITE_WEBSOCKET_PORTER_URL)
    }
  }, [connect])

  useWebSocketRoom({
    room: state.userProfile?.internalUser?.id,
    eventCallbacks: sentinelHandlers,
  })

  return (
    <StatsigProvider
      sdkKey={import.meta.env.VITE_STATSIG_CLIENT_KEY}
      user={{
        userID: state.userProfile?.internalUser?.id,
        email: state.userProfile?.email,
      }}
      options={{
        environment: {
          tier:
            import.meta.env.VITE_ENV === 'production'
              ? 'production'
              : 'development',
        },
      }}
    >
      <Routes>
        {actionCenterAccess && (
          <Route path="/action-center/*" element={<ActionCenter />} />
        )}
        <Route path="/companies/" element={<CompanySearchScreen />} />
        <Route
          path="/companies/:companyId"
          element={<CompanyDetailsScreen />}
        />
        <Route
          path="/companies/:companyId/logs"
          element={<CompanyLogsScreen />}
        />
        <Route
          path="/companies/:companyId/invoice-settings"
          element={<InvoiceSettingsScreen />}
        />
        {hotSettings?.enableVettingCampaigns && (
          <>
            <Route path="/vetting/" element={<VettingCampaignSearchScreen />} />
            <Route
              path="/vetting/new"
              element={<CreateVettingCampaignScreen />}
            />
          </>
        )}
        <Route path="/workers" element={<WorkerSearchScreen />} />
        <Route path="/workers/:workerId" element={<WorkerDetailsScreen />} />
        <Route path="/workers/:workerId/logs" element={<WorkerLogsScreen />} />
        <Route path="/field-monitor" element={<FieldMonitorScreen />} />
        <Route
          path="/schedule/:shiftRequestParentId"
          element={<ScheduleScreen />}
        />
        <Route
          path="/schedule/:shiftRequestParentId/add"
          element={<AddOneOffToScheduleScreen />}
        />
        <Route
          path="/field-monitor/:shiftId"
          element={<ShiftDetailsScreen />}
        />
        <Route
          path="/field-monitor/:shiftId/logs"
          element={<ShiftLogsScreen />}
        />
        <Route path="/incentives" element={<IncentivesMonitor />} />
        <Route path="/incentives/new" element={<CreateIncentive />} />
        <Route
          path="/incentives/:incentiveId"
          element={<IncentiveDetailsScreen />}
        />
        <Route
          path="/worker-vetting/campaigns/new"
          element={<CreateWorkerVettingCampaign />}
        />
        <Route
          path="/worker-vetting/campaigns/:campaignId"
          element={<VettingCampaignDetailsScreen />}
        />
        <Route
          path="/worker-vetting/campaigns"
          element={<WorkerVettingCampaignList />}
        />
        <Route path="/post/:companyId" element={<PostShiftScreen />} />
        <Route path="/tools" element={<ToolsScreen />} />
        <Route path="/timesheet" element={<TimesheetScreen />} />
        <Route path="/billing" element={<BillingScreen />} />
        <Route path="/regions" element={<RegionsScreen />} />
        <Route path="/demo-builder" element={<DemoAccountScreen />} />
        <Route
          path="/company-worker-feedback"
          element={<CompanyWorkerFeedbackScreen />}
        />
        <Route
          path="/billing/invoices/:invoiceId"
          element={<InvoicesDetailsScreen />}
        />
        <Route
          path="/billing/invoices/new"
          element={<InvoicesCreationScreen />}
        />
        <Route path="*" element={<Navigate to="/field-monitor" />} />
        <Route
          path="/field-monitor/:shiftId/messages-log"
          element={<MessageLogsScreen />}
        />
      </Routes>
    </StatsigProvider>
  )
}

export default LoggedIn
