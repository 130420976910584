import { SvgIcon, Text } from '@traba/react-components'

import { theme } from '@traba/theme'
import { Company, ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  formatRepeatsOnForSchedules,
  formatScheduleDateRange,
} from '@traba/utils'
import {
  Button,
  Badge,
  CopyTextIcon,
  Link,
  Icon,
  Row,
} from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { truncateString } from 'src/utils/stringUtils'
import { ButtonVariant } from '../base/Button/types'
import { CompanyHeaderWrapper } from '../CompanyHeader/CompanyHeader.styles'
import { CompanyLogo } from '../CompanyLogo/CompanyLogo'

export interface ScheduleHeaderProps {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  company: Company
}

export const ScheduleHeader = (props: ScheduleHeaderProps) => {
  const { shiftRequestParent, company } = props
  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    (sr) =>
      sr.schedules[0].isRecurringSchedule &&
      !!sr.schedules[0].recurringSchedule,
  )
  const firstSchedule = firstRecurringShiftRequest?.schedules[0]
  const { companyLogo, employerName } = company

  const { state } = useUserContext()
  const tz = useTimezonedDates(firstRecurringShiftRequest?.timezone)

  if (!firstSchedule) {
    return null
  }

  const timeString = tz.getShiftTime(
    firstSchedule.startTime,
    firstSchedule.endTime,
  )

  return (
    <CompanyHeaderWrapper email={state.userProfile?.email || ''}>
      <Row pt={150} alignCenter>
        <Link to={`/companies/${shiftRequestParent.companyId}`} target="_blank">
          <CompanyLogo
            style={{
              fontSize: '40px',
            }}
            companyName={employerName}
            companyLogoUrl={companyLogo}
          />
        </Link>
        <Row flexCol ml={theme.space.med} mt={theme.space.sm}>
          <Row alignCenter>
            <Text variant="h4" mr={theme.space.xxs}>
              {shiftRequestParent.title}
            </Text>
            <Badge title={'schedule'} />
          </Row>
          <Row alignCenter>
            <Link
              to={`/companies/${shiftRequestParent.companyId}`}
              target="_blank"
            >
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {employerName}
              </Button>
            </Link>
            <Text variant="caption" style={{ marginLeft: 12 }}>
              {truncateString(shiftRequestParent.companyId)}
              <CopyTextIcon textToCopy={shiftRequestParent.companyId} />
            </Text>
          </Row>
        </Row>
        <Row flexCol ml={theme.space.sm} mt={theme.space.sm}>
          <Row alignCenter my={theme.space.us} gap={theme.space.xxs}>
            <Icon name="calendar_active" color={theme.colors.brand} />
            <Text variant="body1">{timeString}</Text>
          </Row>
          <Row alignCenter my={theme.space.us}>
            <SvgIcon name="rotateLeft" />
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {formatRepeatsOnForSchedules(
                firstRecurringShiftRequest.schedules,
              )}
            </Text>
          </Row>
        </Row>
        <Row
          flexCol
          ml={theme.space.med}
          mt={theme.space.sm}
          gap={theme.space.xxs}
        >
          <Row alignCenter my={theme.space.us}>
            <Icon name="location" />
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {firstRecurringShiftRequest.shortLocation}
            </Text>
          </Row>
          <Row alignCenter>
            <SvgIcon name="calendar" />
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {formatScheduleDateRange(firstRecurringShiftRequest.schedules)}
            </Text>
          </Row>
        </Row>
      </Row>
    </CompanyHeaderWrapper>
  )
}
