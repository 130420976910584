import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Avatar, Col, Icon, Row } from 'src/components/base'
import {
  WorkerVettingMessageSource,
  WorkerVettingSessionMessage,
} from 'src/types/worker-vetting'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'

interface WorkerVettingMessageItemProps {
  workerVettingMessage: WorkerVettingSessionMessage
  pictureUrl?: string
}

export const WorkerVettingMessageItem = (
  props: WorkerVettingMessageItemProps,
) => {
  const { workerVettingMessage, pictureUrl } = props
  const endAlignMessage =
    workerVettingMessage.source === WorkerVettingMessageSource.worker

  return (
    <Row
      style={{
        justifyContent: endAlignMessage ? 'flex-end' : 'flex-start',
        marginTop: theme.space.xxs,
      }}
    >
      <Row
        justifyEnd
        style={{
          marginBottom: theme.space.xs,
        }}
      >
        {!endAlignMessage && (
          <Row style={{ height: 28, width: 28 }}>
            <Icon name="aiPlatform_active" />
          </Row>
        )}
        <Row
          style={{
            flex: 1,
          }}
        >
          <Col mx={theme.space.xxs}>
            <Card
              style={{
                backgroundColor: endAlignMessage
                  ? theme.colors.Grey10
                  : theme.colors.Violet10,
                paddingTop: theme.space.xxs,
                paddingBottom: theme.space.xxs,
                paddingLeft: theme.space.xs,
                borderWidth: 0,
                alignSelf: endAlignMessage ? 'flex-end' : 'flex-start',
              }}
            >
              <Text variant={'body1'} style={{ wordBreak: 'break-word' }}>
                {workerVettingMessage.message}
              </Text>
            </Card>

            <Row justifyEnd={endAlignMessage} alignCenter>
              <Text
                variant={'body2'}
                style={{
                  textAlign: endAlignMessage ? 'end' : 'start',
                  marginRight: theme.space.xs,
                }}
              >
                {formatTimeDistanceToNow(workerVettingMessage.createdAt)}
              </Text>
            </Row>
          </Col>
        </Row>
        {endAlignMessage &&
          (pictureUrl ? (
            <Avatar src={pictureUrl} size={50} name={'W'} />
          ) : (
            <Row style={{ height: 28, width: 28 }}>
              <Icon name="avatar" />
            </Row>
          ))}
      </Row>
    </Row>
  )
}
