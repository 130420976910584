import {
  WorkerVettingCampaignStatus,
  WorkerVettingSessionStatus,
} from 'src/types/worker-vetting'
import { Badge } from './base'

type VettingSessionStatusBadgeProps = {
  status: WorkerVettingSessionStatus
}

const statusToBadgeVariantDict: Record<WorkerVettingSessionStatus, string> = {
  [WorkerVettingSessionStatus.active]: 'warning',
  [WorkerVettingSessionStatus.canceled]: 'opaqueRed',
  [WorkerVettingSessionStatus.complete]: 'success',
  [WorkerVettingSessionStatus.exited]: 'info',
  [WorkerVettingSessionStatus.stale]: 'orange',
}

export function VettingSessionStatusBadge({
  status,
}: VettingSessionStatusBadgeProps) {
  return <Badge title={status} variant={statusToBadgeVariantDict[status]} />
}

type VettingCampaignStatusBadgeProps = {
  status: WorkerVettingCampaignStatus
}

const campaignStatusToBadgeVariantDict: Record<
  WorkerVettingCampaignStatus,
  string
> = {
  [WorkerVettingCampaignStatus.active]: 'warning',
  [WorkerVettingCampaignStatus.canceled]: 'opaqueRed',
  [WorkerVettingCampaignStatus.completed]: 'success',
}

export function VettingCampaignStatusBadge({
  status,
}: VettingCampaignStatusBadgeProps) {
  return (
    <Badge
      title={status}
      variant={campaignStatusToBadgeVariantDict[status]}
      style={{ height: 28 }}
    />
  )
}
