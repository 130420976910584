import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { NotificationSettingsTables } from '@traba/react-components'
import { User } from '@traba/types'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'

export type UserEditNotificationsModalProps = {
  user: User
  isOpen: boolean
  handleClose: () => void
}

export function UserEditNotificationsModal({
  user,
  handleClose,
  isOpen,
}: UserEditNotificationsModalProps) {
  const { firstName, lastName, uid, companyId, smsConsent } = user
  const { isLoading } = useCompanyUsers(companyId || '')
  const { showError } = useAlert()

  const handleUserHasNoConsent = useCallback(() => {
    showError('User has not agreed to SMS consent yet.')
  }, [showError])

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Modal
      size={MODAL_SIZE.EXTRA_LARGE}
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Edit user for ${firstName} ${lastName}`}
    >
      {companyId && (
        <NotificationSettingsTables
          businessUserId={uid}
          companyId={companyId}
          handleUserHasNoConsent={handleUserHasNoConsent}
          hasSMSConsent={smsConsent?.agreedToSmsConsent}
        />
      )}
    </Modal>
  )
}
