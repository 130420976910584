import { ShiftTag } from '@traba/types'

const BIZ_OPS_POD_TAGS = [
  ShiftTag.National,
  ShiftTag.Pod1,
  ShiftTag.Pod2,
  ShiftTag.Pod3,
  ShiftTag.Pod4,
  ShiftTag.Pod5,
  ShiftTag.Pod6,
  ShiftTag.Pod7,
]

export function hasBizOpsPodTag(shiftTags: ShiftTag[]) {
  return shiftTags.reduce((acc, curr) => {
    return acc || BIZ_OPS_POD_TAGS.includes(curr)
  }, false)
}
