import * as Sentry from '@sentry/react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ActionableRequirement, WorkerRequirementAction } from '@traba/types'
import { useMemo } from 'react'

const WORKER_REQUIREMENT_ACTIONS_LIST_QUERY_KEY = 'useWorkerRequirementActions'

async function getWorkerRequirementActions(workerId: string) {
  try {
    const response = await trabaApi.post('worker-requirement-actions/list', {
      workerId,
    })
    return response.data
  } catch (e: unknown) {
    const errorMessage = `useWorkerRequirementActions -> getActiveWorkerRequirementActions() ERROR ${
      e instanceof Error ? e.message : e
    }`
    Sentry.captureException(e)
    console.error(errorMessage)
  }
}

export const useWorkerRequirementActions = (workerId: string) => {
  const {
    data: workerRequirementActions,
    isLoading: isLoadingWorkerRequirementActions,
    isRefetching: isRefetchingWorkerRequirementActions,
    refetch: refetchWorkerRequirementActions,
  } = useQuery<WorkerRequirementAction[]>({
    queryKey: [WORKER_REQUIREMENT_ACTIONS_LIST_QUERY_KEY, workerId],
    queryFn: () => getWorkerRequirementActions(workerId),
  })

  const hasActivePhoneNumberAction = useMemo(() => {
    return !!workerRequirementActions?.find(
      (action) =>
        action.requirement === ActionableRequirement.PHONE_NUMBER &&
        action.isActive,
    )
  }, [workerRequirementActions])

  return {
    workerRequirementActions,
    isLoadingWorkerRequirementActions,
    isRefetchingWorkerRequirementActions,
    hasActivePhoneNumberAction,
    refetchWorkerRequirementActions,
  }
}

export function useCreateOrUpdateWorkerRequirementAction() {
  const mutation = useMutation({
    mutationFn: async ({
      workerId,
      requirementType,
      actionType,
      expiryTime,
    }: {
      workerId: string
      requirementType: string
      actionType: string
      expiryTime?: Date
    }) => {
      const res = await trabaApi.put(
        `worker-requirement-actions/create-or-update`,
        {
          workerId,
          requirementType,
          actionType,
          ...(expiryTime && { expiryTime: expiryTime.toISOString() }),
        },
      )
      return res.data
    },
  })

  return mutation
}
