import { trabaApi } from '@traba/api-utils'
import { FixedLengthArray } from 'src/types'
import { getQueryParams } from './useApi'

type BufferUpdate = {
  startTime: Date
  businessStartTime: Date | undefined
}

async function getEarlyArrivalBufferUpdates(
  buffer: number,
  startTime: Date,
  businessStartTime: Date | undefined | null,
): Promise<BufferUpdate> {
  const queryParams: FixedLengthArray<
    [string, string | null | undefined | boolean]
  >[] = [
    ['bufferInMinutes', buffer.toString()],
    ['startTime', new Date(startTime).toISOString()],
  ]
  if (businessStartTime) {
    queryParams.push([
      'businessStartTime',
      new Date(businessStartTime).toISOString(),
    ])
  }
  const queryString = getQueryParams(queryParams)
  const res = await trabaApi.get(
    `shifts/updates-for-early-arrival-time-buffer${queryString}`,
  )
  return res.data
}

export function useEarlyArrivalBuffer() {
  return { getEarlyArrivalBufferUpdates }
}
