import * as Sentry from '@sentry/react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  UpdateSegmentsForWorkerShiftRequest,
  WorkersSegmentsMap,
  CostCentersForShiftResponse,
  WorkersWithSegmentsResponse,
  CostCenterResponse,
  CreateCostCenter,
  UpdateCostCenter,
} from '@traba/types'

const COST_CENTER_PAGE_SIZE = 100
const COST_CENTER_QUERY_KEY = 'costCenterQueryKey'
const COST_CENTERS_FOR_SHIFT_QUERY_KEY = 'costCentersForShiftQueryKey'
const WORKERS_WITH_SEGMENTS_QUERY_KEY = 'workersWithSegmentsQueryKey'

async function listCostCenters(companyId: string, page: unknown) {
  try {
    const response = await trabaApi.get(`/companies/${companyId}/cost-center`, {
      params: { page, pageSize: COST_CENTER_PAGE_SIZE },
    })
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCenters(companyId: string) {
  return useInfiniteQuery<CostCenterResponse, Error>({
    queryKey: [COST_CENTER_QUERY_KEY, companyId],
    queryFn: ({ pageParam = 1 }) => listCostCenters(companyId, pageParam),
    getNextPageParam: (lastPage, _allPages) =>
      lastPage.page < lastPage.pageCount ? lastPage.page + 1 : undefined,
    initialPageParam: 1,
    staleTime: FIVE_MINUTES_IN_MS,
  })
}

async function createCostCenter(
  companyId: string,
  costCenterData: CreateCostCenter,
) {
  try {
    const response = await trabaApi.post(
      `/companies/${companyId}/cost-center`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function updateCostCenter(
  companyId: string,
  costCenterData: UpdateCostCenter,
) {
  try {
    const response = await trabaApi.patch(
      `/companies/${companyId}/cost-center/${costCenterData.id}`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function deleteCostCenter(companyId: string, costCenterId: string) {
  try {
    await trabaApi.delete(`/companies/${companyId}/cost-center/${costCenterId}`)
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCentersMutations(companyId: string) {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const createCostCenterMutation = useMutation<void, Error, CreateCostCenter>({
    mutationFn: (costCenterData) => createCostCenter(companyId, costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COST_CENTER_QUERY_KEY, companyId],
      })
      showSuccess('Cost center created successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to create cost center')
    },
  })

  const updateCostCenterMutation = useMutation<void, Error, UpdateCostCenter>({
    mutationFn: (costCenterData) => updateCostCenter(companyId, costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COST_CENTER_QUERY_KEY, companyId],
      })
      showSuccess('Cost center updated successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to update cost center')
    },
  })

  const deleteCostCenterMutation = useMutation<void, Error, string>({
    mutationFn: (costCenterId) => deleteCostCenter(companyId, costCenterId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COST_CENTER_QUERY_KEY, companyId],
      })
      showSuccess('Cost center deleted successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to delete cost center')
    },
  })

  return {
    createCostCenter: createCostCenterMutation.mutateAsync,
    updateCostCenter: updateCostCenterMutation.mutateAsync,
    deleteCostCenter: deleteCostCenterMutation.mutateAsync,
    isCreatingCostCenter: createCostCenterMutation.isPending,
    isUpdatingCostCenter: updateCostCenterMutation.isPending,
    isDeletingCostCenter: deleteCostCenterMutation.isPending,
  }
}

async function getCostCentersForShift(
  companyId: string,
  shiftId: string,
  page: unknown,
): Promise<CostCentersForShiftResponse> {
  try {
    const response = await trabaApi.get(
      `/companies/${companyId}/shifts/${shiftId}/cost-centers`,
      { params: { page } },
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCentersForShift(companyId: string, shiftId: string) {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<CostCentersForShiftResponse, Error>({
      queryKey: [COST_CENTERS_FOR_SHIFT_QUERY_KEY, companyId, shiftId],
      queryFn: ({ pageParam = 1 }) =>
        getCostCentersForShift(companyId, shiftId, pageParam),
      getNextPageParam: (lastPage, _allPages) =>
        lastPage.page < lastPage.pageCount ? lastPage.page + 1 : undefined,
      initialPageParam: 1,
      staleTime: FIVE_MINUTES_IN_MS,
    })

  return {
    costCenterPages: data,
    fetchMoreCostCenters: fetchNextPage,
    hasMoreCostCenters: hasNextPage,
    isFetchingMoreCostCenters: isFetchingNextPage,
  }
}

async function getWorkersWithSegments(
  companyId: string,
  shiftId: string,
): Promise<WorkersSegmentsMap> {
  try {
    const response = await trabaApi.get<WorkersWithSegmentsResponse>(
      `/companies/${companyId}/shifts/${shiftId}/worker-details`,
    )
    return response.data.members.reduce((acc, worker) => {
      acc[worker.worker.id] = worker
      return acc
    }, {} as WorkersSegmentsMap)
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useWorkersWithSegments(companyId: string, shiftId: string) {
  const { data } = useQuery<WorkersSegmentsMap, Error>({
    queryKey: [WORKERS_WITH_SEGMENTS_QUERY_KEY, companyId, shiftId],
    queryFn: () => getWorkersWithSegments(companyId, shiftId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return { data }
}

async function updateSegmentsForWorkerShift(
  companyId: string,
  shiftId: string,
  workerId: string,
  updatedSegments: UpdateSegmentsForWorkerShiftRequest,
): Promise<void> {
  try {
    await trabaApi.put(
      `/companies/${companyId}/shifts/${shiftId}/workers/${workerId}/cost-centers`,
      updatedSegments,
    )
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useWorkersWithSegmentsMutation(
  companyId: string,
  shiftId: string,
  workerId: string,
) {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending } = useMutation<
    void,
    Error,
    UpdateSegmentsForWorkerShiftRequest,
    unknown
  >({
    mutationFn: (updatedSegments: UpdateSegmentsForWorkerShiftRequest) =>
      updateSegmentsForWorkerShift(
        companyId,
        shiftId,
        workerId,
        updatedSegments,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WORKERS_WITH_SEGMENTS_QUERY_KEY, companyId, shiftId],
      })
    },
  })

  return { updateWorkerShiftSegments: mutateAsync, isUpdating: isPending }
}
