import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import React, { useEffect, useRef, useState } from 'react'
import { Icon, Button, Row, Badge } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  DropdownContainer,
  ListContainer,
  ListItem,
  ListStyling,
} from './Dropdown.styles'

export interface DropdownProps {
  dropdownOptions: DropdownElement[]
  orientation?: 'right' | 'left'
  onOpenDropdown?: () => void
  buttonVariant?: ButtonVariant
  buttonStyling?: React.CSSProperties
  dropdownStyling?: React.CSSProperties
  containerStyling?: React.CSSProperties
  listContainerStyling?: React.CSSProperties
  listItemStyling?: React.CSSProperties
  title?: string
  loading?: boolean
  useBadge?: boolean
  badgeVariant?: string
}

export type DropdownElement = {
  label: string
  handleClick: () => void
  disabled?: boolean
  icon?: () => JSX.Element
  toolTipPrompt?: string
}

export const Dropdown = (props: DropdownProps) => {
  const ref = useRef<any>()
  useEffect(() => {
    const element = ref.current
    const handleClickOutside = (event: MouseEvent) => {
      if (element && !element.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [open, setOpen] = useState<boolean>(false)

  function handleClick() {
    if (!open && typeof props.onOpenDropdown === 'function') {
      props.onOpenDropdown()
    }
    setOpen(!open)
  }

  return (
    <DropdownContainer ref={ref} style={{ ...props.containerStyling }}>
      {props.useBadge ? (
        <Badge
          variant={props.badgeVariant || 'info'}
          title={props.title || ''}
          onClick={handleClick}
          style={{ cursor: 'pointer', ...props.buttonStyling }}
        />
      ) : (
        <Button
          style={{
            ...props.buttonStyling,
          }}
          variant={props.buttonVariant || ButtonVariant.TRANSPARENT}
          onClick={handleClick}
          loading={props.loading}
        >
          {props.title !== undefined ? (
            props.title
          ) : (
            <Icon name={open ? 'elipses_active' : 'elipses'} />
          )}
        </Button>
      )}
      {open && (
        <ListContainer
          style={{ ...props.listContainerStyling }}
          orientation={props.orientation}
        >
          <ListStyling style={{ ...props.dropdownStyling }}>
            {props.dropdownOptions.map((option, i) => {
              const disabledStyles = {
                color: theme.colors.Grey60,
                backgroundColor: theme.colors.Grey10,
                cursor: 'default',
              }
              const itemStyle = {
                ...props.listItemStyling,
                ...(option.disabled ? disabledStyles : {}),
              }
              return (
                <ListItem
                  key={i}
                  onClick={() => {
                    if (!option.disabled) {
                      option.handleClick()
                      setOpen(false)
                    }
                  }}
                  disabled={option.disabled}
                >
                  <Row style={itemStyle} gap={theme.space.xxs}>
                    {!!option.icon && option.icon()}
                    {option.label}
                    {option.toolTipPrompt && (
                      <Tooltip title={option.toolTipPrompt}>
                        <div>
                          <Icon name="info" />
                        </div>
                      </Tooltip>
                    )}
                  </Row>
                </ListItem>
              )
            })}
          </ListStyling>
        </ListContainer>
      )}
    </DropdownContainer>
  )
}
