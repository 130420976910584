import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { FormatLineItemType, LineItemsPreviewResponseDto } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'

export default function useInvoiceCreation({
  companyId,
  formatByType,
  shiftIds,
  validateInvoiceGroupId,
}: {
  companyId: string
  formatByType: FormatLineItemType
  shiftIds: string[]
  validateInvoiceGroupId?: string
}) {
  async function previewLineItems(
    companyId: string,
    formatByType: FormatLineItemType,
    shiftIds: string[],
    validateInvoiceGroupId?: string,
  ): Promise<LineItemsPreviewResponseDto> {
    try {
      const res = await trabaApi.get<LineItemsPreviewResponseDto>(
        'invoices/line-items',
        {
          params: {
            companyId,
            formatByType,
            shiftIds,
            validateInvoiceGroupId,
          },
        },
      )
      return res.data
    } catch (error: any) {
      console.error(
        'useInvoiceCreation -> previewLineItems ERROR',
        error.message ?? error,
      )
      Sentry.captureException(error)
      throw error
    }
  }

  const {
    isFetching,
    isLoading,
    isError,
    data: lineItemsPreview,
    error,
    isFetched,
    refetch,
  } = useQuery<LineItemsPreviewResponseDto, Error>(
    [
      'invoice-preview-line-items',
      companyId,
      formatByType,
      shiftIds,
      validateInvoiceGroupId,
    ],
    () =>
      previewLineItems(
        companyId,
        formatByType,
        shiftIds,
        validateInvoiceGroupId,
      ),
    {
      refetchOnWindowFocus: true,
      retry: 1,
    },
  )

  const createInvoiceDraft = useMutation<undefined, AxiosError>((_) =>
    trabaApi.post('invoices', {
      companyId,
      formatByType,
      shiftIds,
      invoiceGroupId: validateInvoiceGroupId,
    }),
  )

  return {
    isFetching,
    isLoading,
    isError,
    lineItemsPreview,
    error,
    isFetched,
    refetch,
    createInvoiceDraft: createInvoiceDraft.mutateAsync,
  }
}
