import { Tooltip } from '@mui/material'
import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { getUserNameWithRole } from '@traba/utils'
import { Row } from 'src/components/base'
import { useLocationAssignedActiveUsers } from 'src/hooks/useCompanyUsers'

interface LocationAssignedUsersWithTooltipProps {
  locationId: string
  companyId: string
}

export default function LocationAssignedUsersWithTooltip({
  companyId,
  locationId,
}: LocationAssignedUsersWithTooltipProps) {
  const { locationAssignedUsers, isLoading } = useLocationAssignedActiveUsers(
    companyId,
    locationId,
  )
  const membersString = locationAssignedUsers
    .filter((user) => !!user.role)
    .map(getUserNameWithRole)
    .join(', ')

  return (
    <Tooltip title={membersString}>
      <div>
        <Row gap={theme.space.xxxs} alignCenter>
          <Row alignCenter>
            <SvgIcon
              name="location"
              size={16}
              color={theme.colors.MidnightBlue}
            />
          </Row>

          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            `${locationAssignedUsers.length} user${makePlural(locationAssignedUsers.length)}`}
        </Row>
      </div>
    </Tooltip>
  )
}
