import { CircularProgress, Tooltip, Switch } from '@mui/material'
import { ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE } from '@traba/consts'
import { FileType } from '@traba/hooks'
import { Text, Col, InputInfoCalloutRow } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeLevelType,
  BGCRequirement,
  CertificationEntry,
  Company,
  COMPANY_WIDE_ID,
  GenderPreference,
  HotSettings,
  InputStatus,
  RequiredAttributeLevel,
  RoleAttribute,
  RoleAttributeCategory,
  RoleAttributeStatus,
  RoleAttributeVisibility,
  ShiftAttribute,
  TrainingVideo,
  WorkerCertificationType,
} from '@traba/types'
import { isRoleNameDupeInSameLocation } from '@traba/utils'
import { upperFirst } from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Button, Icon, Input, Modal, Row, Select } from 'src/components/base'
import { CheckboxThemed } from 'src/components/base/CheckboxThemed/CheckboxThemed'
import { useAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { CreateRoleData, EditRoleData, useRoles } from 'src/hooks/useRoles'
import { useTrainings } from 'src/hooks/useTrainings'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { filterNonOpsLevelingLanguages } from 'src/utils/attributeUtils'
import { getAttributeAllowOpsLeveling } from 'src/utils/attributeUtils'
import {
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  PAY_RATE_DEFAULT,
  getMinHourlyPayRate,
} from 'src/utils/moneyUtils'

import { ButtonVariant } from '../base/Button/types'
import Checkbox from '../base/Checkbox'
import Divider from '../base/Divider'
import { NumberInput } from '../base/Input/NumberInput'
import SelectableCard from '../base/SelectableCard/SelectableCard'
import { BGCRequirementSelector } from '../BGCRequirementSelector/BGCRequirementSelector'
import { DefaultPayRateComponent } from '../DefaultPayRate'
import { RadioButton } from '../RadioButton/RadioButton'
import { RequiredAttributeLevelsSections } from '../RequiredAttributeLevelsSections/RequiredAttributeLevelsSections'
import { UploadVideoModal } from '../UploadVideo/UploadVideoModal'
import { GenderPreferenceSelector } from './GenderPreferenceSelector'
import { LocationSingleSearchSelector } from './LocationSingleSearchSelector'

// TODO(polyphilz): This sort is legacy; confirm a new sort order when we
// combine this and the biz app implementation.
const RESPONSIBILITY_SORT = [
  'order_picking',
  'packing',
  'loading',
  'unloading',
  'sorting',
  'stocking',
  'organizing',
  'inventory_control',
  'quality_control',
  'assembly_line',
  'building',
]

// TODO(polyphilz): This sort is legacy; confirm a new sort order when we
// combine this and the biz app implementation.
const FORKLIFT_AND_EQUIPMENT_SORT = [
  'forklift',
  'cherry_picker',
  'hi_low_reach_truck',
  'rider_pallet_jack',
  'pallet_jack',
  'pallet_wrapper',
  'extension_ladder',
  'computer',
]

// TODO(polyphilz): This sort is legacy; confirm a new sort order when we
// combine this and the biz app implementation.
const GEAR_SORT = [
  'steel_toe_boots',
  'highlighter_vest',
  'hard_hat',
  'steel_hammer',
  'work_gloves',
]

const LANGUAGES_SORT = ['en', 'es']

function getOtherType(
  displayName: string,
  category: RoleAttributeCategory,
  companyDescription?: string,
): RoleAttribute {
  return {
    category,
    displayName,
    companyDescription,
    type: `other_${category}`,
    reportable: true,
    visibility: RoleAttributeVisibility.Visible,
    status: RoleAttributeStatus.Inactive,
    levelType: AttributeLevelType.BASE_LEVELING,
    allowOpsLeveling: false,
  }
}

function genericSort(a: RoleAttribute, b: RoleAttribute, list: string[]) {
  const indexA = list.indexOf(a.type)
  const indexB = list.indexOf(b.type)

  if (indexA === -1) {
    return 1
  }
  if (indexB === -1) {
    return -1
  }

  return indexA - indexB
}

function getWeight(type: string) {
  const match = type.match(/([0-9]+)_lbs/)
  if (!match || !match[1] || isNaN(+match[1])) {
    return 0
  }
  return +match[1]
}

function liftingSort(a: RoleAttribute, b: RoleAttribute) {
  return getWeight(a.type) - getWeight(b.type)
}

function ResponsibilityListItem(props: {
  type: string
  displayName: string
  companyDescription?: string
  selected: boolean
  onChange: () => void
}) {
  return (
    <Row style={{ marginTop: theme.space.xs, width: '30%' }}>
      <Checkbox
        checked={props.selected}
        defaultChecked={props.selected}
        onChange={() => props.onChange()}
        label={
          <Row alignCenter>
            <Text variant="h7">{upperFirst(props.displayName)}</Text>
            <Tooltip
              title={props.companyDescription ?? ''}
              style={{ display: 'flex', position: 'relative' }}
            >
              <div
                style={{
                  marginLeft: theme.space.xxs,
                  position: 'relative',
                  top: 5,
                }}
              >
                <Icon name="info" type="svg" color={theme.colors.Grey50} />
              </div>
            </Tooltip>
          </Row>
        }
      />
    </Row>
  )
}

interface RoleStepProps {
  role: Partial<CreateRoleData>
  setRole: (r: Partial<CreateRoleData>) => void
  previousRoleId: string
  setPreviousRoleId: (r: string) => void
  attributes: RoleAttribute[]
  certifications: CertificationEntry[]
  toggleAttribute: (a: ShiftAttribute) => void
  setRequiredAttributeLevels: (reqAttrLvls: RequiredAttributeLevel[]) => void
  isAttributeSelected: (a: ShiftAttribute) => boolean
  toggleCertification: (c: string) => void
  isCertificationSelected: (c: string) => boolean
  isGMPTrainingSelected: boolean
  setIsGMPTrainingSelected: (selected: boolean) => void
  forkliftCertificationInteracted: boolean
  setForkliftCertificationInteracted: (val: boolean) => void
  trainingVideos: TrainingVideo[]
  requiredForAllTrainingVideos?: TrainingVideo[]
  closeUploadVideoModal: () => void
  onUploadSuccess: (videoId: string) => void
  openUploadVideoModal: () => void
  isModalOpen: boolean
  setSelectedVideoIds: (vIds: string[]) => void
  selectedVideoIds: string[]
  nextStep: () => void
  isEdit: boolean
  minHourlyPayRate: number
  company: Company
  hotSettings?: HotSettings
  currentRoleId?: string
}

function RoleRequiredAttributeLevelsStep(props: RoleStepProps) {
  const { attributes, setRequiredAttributeLevels, role } = props
  return (
    <>
      <Text variant="h5" mb={theme.space.sm} ml={theme.space.sm}>
        Select the required attribute levels for this role
      </Text>
      <RequiredAttributeLevelsSections
        attributes={attributes}
        setRequiredAttributeLevels={setRequiredAttributeLevels}
        requiredAttributeLevels={role.requiredAttributeLevels ?? []}
      />
    </>
  )
}

function RoleResponsibilityStep(props: RoleStepProps) {
  const responsibilities = useMemo(
    () => [
      ...props.attributes
        .filter(
          (a) =>
            a.category === RoleAttributeCategory.Responsibilities &&
            (!props.hotSettings?.allowStoringRequiredAttributeLevel ||
              !getAttributeAllowOpsLeveling(a.type, props.attributes)),
        )
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
      getOtherType(
        'Other',
        RoleAttributeCategory.Responsibilities,
        'Enter additional custom responsibilities.',
      ),
    ],
    [props.attributes, props?.hotSettings?.allowStoringRequiredAttributeLevel],
  )

  const manuSkills = useMemo(
    () => [
      ...props.attributes
        .filter(
          (a) =>
            a.category === RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION &&
            (!props.hotSettings?.allowStoringRequiredAttributeLevel ||
              !getAttributeAllowOpsLeveling(a.type, props.attributes)),
        )
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
    ],
    [props.attributes, props?.hotSettings?.allowStoringRequiredAttributeLevel],
  )

  const foodPrepSkills = useMemo(
    () => [
      ...props.attributes
        .filter(
          (a) =>
            a.category === RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE &&
            (!props.hotSettings?.allowStoringRequiredAttributeLevel ||
              !getAttributeAllowOpsLeveling(a.type, props.attributes)),
        )
        .sort((a, b) => genericSort(a, b, RESPONSIBILITY_SORT)),
    ],
    [props.attributes, props?.hotSettings?.allowStoringRequiredAttributeLevel],
  )

  function onChange(type: string, category: RoleAttributeCategory) {
    props.toggleAttribute({
      type,
      category,
    })
    if (type === 'gmp') {
      props.setIsGMPTrainingSelected(true)
    }
  }

  const otherSelected = !!props.role.requiredAttributes?.some(
    (ra) => ra.type === `other_${RoleAttributeCategory.Responsibilities}`,
  )

  return (
    <>
      <Text variant="h5">
        What will workers be responsible for in this role?
      </Text>
      <Text variant="h6" mt={theme.space.xs}>
        General warehousing responsibilities
      </Text>
      <Row
        justifyBetween
        style={{
          flexWrap: 'wrap',
          backgroundColor: theme.colors.Grey10,
          padding: theme.space.xs,
        }}
      >
        {responsibilities.map((o) => (
          <ResponsibilityListItem
            {...o}
            key={`${o.type}_${o.category}`}
            selected={props.isAttributeSelected(o)}
            onChange={() => {
              onChange(o.type, o.category)
            }}
          />
        ))}
      </Row>
      {otherSelected ? (
        <Input
          rows={2}
          label="Other"
          placeholder="Please list additional responsibilities here."
          type="textarea"
          value={
            (props.role.freeformAttributes &&
              props.role.freeformAttributes[
                RoleAttributeCategory.Responsibilities
              ]) ??
            ''
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.setRole({
              freeformAttributes: {
                ...props.role.freeformAttributes,
                [RoleAttributeCategory.Responsibilities]: e.target.value,
              },
            })
          }
          width="100%"
          minHeight={theme.space.xl}
          containerStyle={{ marginTop: theme.space.sm }}
        />
      ) : null}
      {manuSkills.length > 0 && (
        <>
          <Text variant="h6" mt={theme.space.xs}>
            Manufacturing and Production
          </Text>
          <Row
            justifyBetween
            style={{
              flexWrap: 'wrap',
              backgroundColor: theme.colors.Grey10,
              padding: theme.space.xs,
            }}
          >
            {manuSkills.map((o) => (
              <ResponsibilityListItem
                {...o}
                key={`${o.type}_${o.category}`}
                selected={props.isAttributeSelected(o)}
                onChange={() => {
                  onChange(o.type, o.category)
                }}
              />
            ))}
          </Row>
        </>
      )}
      {foodPrepSkills.length > 0 && (
        <>
          <Text variant="h6" mt={theme.space.xs}>
            Food Preparation and Service
          </Text>
          <Row
            justifyBetween
            style={{
              flexWrap: 'wrap',
              backgroundColor: theme.colors.Grey10,
              padding: theme.space.xs,
            }}
          >
            {foodPrepSkills.map((o) => (
              <ResponsibilityListItem
                {...o}
                key={`${o.type}_${o.category}`}
                selected={props.isAttributeSelected(o)}
                onChange={() => {
                  onChange(o.type, o.category)
                }}
              />
            ))}
          </Row>
        </>
      )}
    </>
  )
}

function RoleOtherAttributesStep(props: RoleStepProps) {
  const lifting = useMemo(
    () => [
      getOtherType('No weight requirement', RoleAttributeCategory.Lifting),
      ...props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Lifting)
        .sort((a, b) => liftingSort(a, b)),
    ],
    [props.attributes],
  )
  const forkliftAndEquipment = useMemo(() => {
    const allAttributes = [
      ...props.attributes.filter(
        (a) => a.category === RoleAttributeCategory.Forklift,
      ),
      ...props.attributes.filter(
        (a) => a.category === RoleAttributeCategory.Equipment,
      ),
    ]

    const filteredAttributes = allAttributes.filter(
      (a) =>
        !props.hotSettings?.allowStoringRequiredAttributeLevel ||
        !getAttributeAllowOpsLeveling(a.type, props.attributes),
    )

    return [
      ...filteredAttributes.sort((a, b) =>
        genericSort(a, b, FORKLIFT_AND_EQUIPMENT_SORT),
      ),
      getOtherType('Other', RoleAttributeCategory.Equipment),
    ]
  }, [props.attributes, props.hotSettings])
  const languages = useMemo(
    () =>
      props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Languages)
        .sort((a, b) => genericSort(a, b, LANGUAGES_SORT)),
    [props.attributes],
  )
  const certifications = useMemo(
    () =>
      props.certifications.filter(
        (c) =>
          c.type !== WorkerCertificationType.OSHA_FORKLIFT &&
          props.company.enabledCertifications?.includes(c.type),
      ),
    [props.certifications, props.company.enabledCertifications],
  )

  const liftingSelected = props.role.requiredAttributes?.find(
    (ra) => ra.category === RoleAttributeCategory.Lifting,
  )

  function handleLiftingChange(type: string) {
    props.setRole({
      requiredAttributes: (props.role.requiredAttributes || [])
        .filter((ra) => ra.category !== RoleAttributeCategory.Lifting)
        .concat(
          type !== ''
            ? [{ type, category: RoleAttributeCategory.Lifting }]
            : [],
        ),
    })
  }

  const forkliftCertificationSelected = props.isCertificationSelected(
    WorkerCertificationType.OSHA_FORKLIFT,
  )

  const forkliftSelected =
    !!props.role.requiredAttributes?.some(
      (ra) => ra.category === RoleAttributeCategory.Forklift,
    ) ||
    !!props.role.requiredAttributeLevels?.some(
      (ral) => ral.attribute.category === RoleAttributeCategory.Forklift,
    )

  const otherSelected = !!props.role.requiredAttributes?.some(
    (ra) => ra.type === `other_${RoleAttributeCategory.Equipment}`,
  )

  return (
    <>
      {!props.hotSettings?.allowStoringRequiredAttributeLevel && (
        <>
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            How much weight will workers need to lift in this role?
          </Text>
          <Select
            placeholder="Select amount…"
            value={liftingSelected?.type ?? ''}
            handleSelect={handleLiftingChange}
            menuItems={lifting.map((l) => ({
              value: l.type,
              label: upperFirst(l.displayName),
            }))}
          />
        </>
      )}
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        What equipment will be used on the job?
      </Text>
      <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
        {forkliftAndEquipment.map((o) => (
          <SelectableCard
            key={`${o.type}_${o.category}`}
            label={upperFirst(o.displayName)}
            onClick={() => props.toggleAttribute(o)}
            selected={props.isAttributeSelected(o)}
          />
        ))}
      </Row>
      {otherSelected && (
        <Row mt={theme.space.sm}>
          <Input
            rows={2}
            label="Other"
            placeholder="Please list additional equipment here."
            type="textarea"
            value={
              (props.role.freeformAttributes &&
                props.role.freeformAttributes[
                  RoleAttributeCategory.Equipment
                ]) ??
              ''
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.setRole({
                freeformAttributes: {
                  ...props.role.freeformAttributes,
                  [RoleAttributeCategory.Equipment]: e.target.value,
                },
              })
            }
            width="100%"
            minHeight={theme.space.xl}
          />
        </Row>
      )}
      {forkliftSelected ? (
        <>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
          >
            Is OSHA forklift certification required for this role?
          </Text>
          <Row alignCenter style={{ columnGap: theme.space.xl }}>
            <Col style={{ display: 'flex', flex: '0 1 auto' }}>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => {
                  if (!props.forkliftCertificationInteracted) {
                    props.setForkliftCertificationInteracted(true)
                    return
                  }
                  props.toggleCertification(
                    WorkerCertificationType.OSHA_FORKLIFT,
                  )
                }}
              >
                <RadioButton
                  selected={
                    !props.forkliftCertificationInteracted
                      ? false
                      : !forkliftCertificationSelected
                  }
                />
                <Text variant="body1">Preferred</Text>
              </Row>
            </Col>
            <Col style={{ display: 'flex', flex: '0 1 auto' }}>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => {
                  if (!props.forkliftCertificationInteracted) {
                    props.setForkliftCertificationInteracted(true)
                  }
                  props.toggleCertification(
                    WorkerCertificationType.OSHA_FORKLIFT,
                  )
                }}
              >
                <RadioButton
                  selected={
                    !props.forkliftCertificationInteracted
                      ? false
                      : forkliftCertificationSelected
                  }
                />
                <Text variant="body1">Required</Text>
              </Row>
            </Col>
          </Row>
        </>
      ) : null}
      <BGCRequirementSelector
        title={`What's the role's Background Check Requirement?`}
        selectedExtraBGCRequirement={props.role.extraBGCRequirement}
        handleSelectExtraBGCRequirement={(value: BGCRequirement) =>
          props.setRole({ extraBGCRequirement: value })
        }
      />
      {
        <>
          {!!certifications.length && (
            <Text
              variant="h5"
              style={{
                marginTop: theme.space.sm,
                marginBottom: theme.space.xs,
              }}
            >
              Any additional requirements?
            </Text>
          )}
          <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
            {certifications.map((o) => (
              <SelectableCard
                key={o.type}
                label={o.name}
                onClick={() => props.toggleCertification(o.type)}
                selected={props.isCertificationSelected(o.type)}
              />
            ))}
            {!props.hotSettings?.allowStoringRequiredAttributeLevel &&
              languages.map((o) => (
                <SelectableCard
                  key={`${o.type}_${o.category}`}
                  label={upperFirst(o.displayName)}
                  onClick={() => props.toggleAttribute(o)}
                  selected={props.isAttributeSelected(o)}
                />
              ))}
          </Row>
        </>
      }
      <GenderPreferenceSelector
        selectedGenderPreference={props.role.genderPreference}
        setSelectedGenderPreference={(value: GenderPreference | undefined) =>
          props.setRole({ genderPreference: value })
        }
      />
    </>
  )
}

function RoleGearStep(props: RoleStepProps) {
  const gear = useMemo(
    () =>
      props.attributes
        .filter((a) => a.category === RoleAttributeCategory.Gear)
        .sort((a, b) => genericSort(a, b, GEAR_SORT)),
    [props.attributes],
  )

  return (
    <>
      <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
        What should workers wear in this role?
      </Text>
      <Input
        rows={4}
        placeholder="Describe the type, colors, and style of clothing…"
        type="textarea"
        value={props.role.requiredAttire}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.setRole({ requiredAttire: e.target.value })
        }
        width="100%"
        minHeight={theme.space.xl}
      />
      {!props.hotSettings?.allowStoringRequiredAttributeLevel && (
        <>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.xs }}
          >
            What gear will workers need to bring?
          </Text>
          <Row style={{ flexWrap: 'wrap', gap: theme.space.xxs }}>
            {gear.map((o) => (
              <SelectableCard
                key={`${o.type}_${o.category}`}
                label={upperFirst(o.displayName)}
                onClick={() => props.toggleAttribute(o)}
                selected={props.isAttributeSelected(o)}
              />
            ))}
          </Row>
        </>
      )}
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        What training videos are required?
        <Text
          style={{ marginLeft: theme.space.xxs }}
          variant="link"
          onClick={() => {
            props.openUploadVideoModal()
          }}
        >
          Upload new video
        </Text>
      </Text>
      {props.trainingVideos.map((tv) => {
        const isRequired =
          props.requiredForAllTrainingVideos?.findIndex(
            (v) => v.id === tv.id,
          ) !== -1
        return (
          <Row my={theme.space.xxs}>
            <CheckboxThemed
              key={tv.id}
              disabled={isRequired}
              selected={props.selectedVideoIds.includes(tv.id)}
              onClick={() => {
                if (isRequired) {
                  return
                }
                const idx = props.selectedVideoIds.findIndex(
                  (videoId) => videoId === tv.id,
                )
                const newVideoIds = [...props.selectedVideoIds]
                if (idx === -1) {
                  newVideoIds.push(tv.id)
                } else {
                  newVideoIds.splice(idx, 1)
                }
                props.setSelectedVideoIds(newVideoIds)
                props.setRole({ videoIds: newVideoIds })
              }}
            />
            <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
              {tv.name}
            </Text>
          </Row>
        )
      })}
      {props.attributes.findIndex((attr) => attr.type === 'gmp') !== -1 && (
        <>
          <Text
            variant="h5"
            style={{
              marginTop: theme.space.med,
              marginBottom: theme.space.xs,
            }}
          >
            What Traba training modules are required?
          </Text>
          <Row my={theme.space.xxs}>
            <CheckboxThemed
              selected={props.isGMPTrainingSelected}
              onClick={() => {
                props.setIsGMPTrainingSelected(!props.isGMPTrainingSelected)
              }}
            />
            <Col mx={theme.space.xs}>
              <Text variant="body1">
                Traba Good Manufacturing Practices Awareness Education
              </Text>
              <Text variant="body2">
                This is selected because you picked Good Manufacturing Practices
                as a required responsibility for this role. We offer GMP
                awareness education for workers through the app.
              </Text>
            </Col>
          </Row>
        </>
      )}
      <Modal
        isOpen={props.isModalOpen}
        handleClose={props.closeUploadVideoModal}
      >
        <UploadVideoModal
          companyId={props.company.companyId}
          onClose={props.closeUploadVideoModal}
          onUploadSuccess={props.onUploadSuccess}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
    </>
  )
}

function RoleDetailStep(props: RoleStepProps) {
  const { hotSettings } = useHotSettings()
  const { roles } = useRoles({ companyId: props.company.companyId })
  const [roleNameError, setRoleNameError] = useState<string | undefined>(
    undefined,
  )
  const { roleName, locationId: roleLocationId } = props.role

  const isRoleNameDupeInLocation = useCallback(
    (roleName: string, locationId?: string) => {
      if (!locationId) {
        // if location ID has not been set yet, do not check
        return false
      }

      return isRoleNameDupeInSameLocation({
        roles,
        newRoleName: roleName,
        newRoleLocationId: locationId,
        currentRoleId: props.currentRoleId,
      })
    },
    [roles, props.currentRoleId],
  )

  const onBlurRoleName = useCallback(() => {
    const isNameDuplicatedInLocation =
      roleName && isRoleNameDupeInLocation(roleName, roleLocationId)
    setRoleNameError(
      isNameDuplicatedInLocation
        ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
        : undefined,
    )
  }, [roleName, roleLocationId, isRoleNameDupeInLocation])

  const onChangeRoleLocation = useCallback(
    (locationId: string | undefined) => {
      props.setRole({ locationId })
      const isNameDuplicatedInLocation =
        roleName && isRoleNameDupeInLocation(roleName, locationId)
      setRoleNameError(
        isNameDuplicatedInLocation
          ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
          : undefined,
      )
    },
    [roles, roleName, isRoleNameDupeInLocation],
  )

  return (
    <>
      <Text variant="h5" style={{ marginBottom: theme.space.sm }}>
        Tell us about this role
      </Text>
      <Col mb={theme.space.sm}>
        <Input
          label="Name"
          placeholder="e.g. Warehouse Associate"
          value={props.role.roleName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.setRole({ roleName: e.target.value })
          }
          style={{ minWidth: '100%' }}
          onBlur={onBlurRoleName}
          inputStatus={roleNameError ? InputStatus.error : undefined}
          errorMessage={roleNameError}
        />
      </Col>
      {hotSettings?.enableRegionalAccessPhase1 && (
        <Col mb={theme.space.sm} gap={theme.space.xxs}>
          <LocationSingleSearchSelector
            onChange={onChangeRoleLocation}
            selectedLocationId={props.role.locationId}
            companyId={props.company.companyId}
            disabled={props.isEdit}
          />

          {props.isEdit && (
            <InputInfoCalloutRow text="Cannot edit location of a role" />
          )}
        </Col>
      )}
      <Input
        label="Overview"
        placeholder="Please share a brief overview of this role. This information will be displayed to workers in the Traba app. In the following steps, you’ll be asked to add job details and requirements."
        type="textarea"
        value={props.role.roleDescription}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.setRole({ roleDescription: e.target.value })
        }
        width="100%"
        minHeight={theme.space.xl}
      />
      <DefaultPayRateComponent
        defaultPayRate={props.role.defaultPayRate!}
        setDefaultPayRate={(defaultPayRate) =>
          props.setRole({ defaultPayRate })
        }
        minHourlyPayRate={props.minHourlyPayRate}
      />
      <>
        <Text
          variant="h5"
          style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
        >
          What should be the minimum worker age for this shift?
        </Text>
        <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
          Workers will have to be at least this age to join the shift.
        </Text>
        <NumberInput
          containerStyle={{ marginTop: theme.space.xxs }}
          setValue={(v) => {
            props.setRole({ minimumAgeRequirement: v })
          }}
          decimals={0}
          value={props.role.minimumAgeRequirement}
          width={'200'}
          label="Min Age"
        />
      </>
      <>
        <Text
          variant="h5"
          style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
        >
          Should Workers need to upload a resume?
        </Text>
        <Switch
          inputProps={{ 'aria-label': 'controlled' }}
          checked={props.role.resumeUploadRequired}
          onClick={() =>
            props.setRole({
              resumeUploadRequired: !props.role.resumeUploadRequired,
            })
          }
        />
      </>
    </>
  )
}

type RoleStepperProps = {
  initialRoleState: Partial<CreateRoleData>
  fromPrevious: boolean
  onCancel: () => void
  onConfirmCreate?: (role: CreateRoleData) => void
  onConfirmEdit?: (role: EditRoleData) => void
  submitLoading: boolean
  isEdit: boolean
  company: Company
  currentRoleId?: string
}

export const RoleStepper = (props: RoleStepperProps) => {
  const {
    initialRoleState,
    fromPrevious,
    onCancel,
    onConfirmCreate,
    onConfirmEdit,
    submitLoading,
    isEdit,
    company,
    currentRoleId,
  } = props

  const [role, setRole] = useReducer(
    (state: Partial<CreateRoleData>, value: Partial<CreateRoleData>) => ({
      ...state,
      ...value,
    }),
    initialRoleState,
  )

  const [step, setStep] = useState(0)
  const { attributes } = useAttributes(undefined, company.category)
  const { certifications } = useCertifications()
  const { trainings } = useTrainings()
  const { roles } = useRoles({ companyId: company.companyId })
  const { trainingVideos, requiredForAllTrainingVideos } = useTrainingVideos(
    company.companyId,
  )
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])
  const [previousRoleId, setPreviousRoleId] = useState('')

  const gmpTrainingId = trainings?.find(
    (training) =>
      training.nameEn === 'Good Manufacturing Practices Awareness Education',
  )?.id

  const [isGMPTrainingSelected, setIsGMPTrainingSelected] = useState<boolean>(
    !!gmpTrainingId && !!role.requiredTrainingIds?.includes(gmpTrainingId),
  )
  const { hotSettings } = useHotSettings()

  const [forkliftCertificationInteracted, setForkliftCertificationInteracted] =
    useState(
      initialRoleState.requiredAttributes?.some(
        (attr) => attr.category === RoleAttributeCategory.Forklift,
      ) || false,
    )
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const preSelectedVideoIds = trainingVideos
      .filter((tv) => tv.requiredForAll)
      .map((tv) => tv.id)

    const videoIds = Array.from(
      new Set([...preSelectedVideoIds, ...(initialRoleState.videoIds ?? [])]),
    )
    setSelectedVideoIds(videoIds)
    setRole({ videoIds: videoIds })
  }, [trainingVideos, initialRoleState?.videoIds])

  function openUploadVideoModal() {
    setIsModalOpen(true)
  }

  function closeUploadVideoModal() {
    setIsModalOpen(false)
  }

  function onUploadSuccess(videoId: string) {
    setSelectedVideoIds([...selectedVideoIds, videoId])
    setRole({ videoIds: [...selectedVideoIds, videoId] })
  }

  const roleAttributes = useMemo(
    () => attributes.filter(filterNonOpsLevelingLanguages),
    [attributes],
  )
  const selectedAttributes = useMemo(
    () => new Set(role.requiredAttributes?.map((ra) => ra.type)),
    [role.requiredAttributes],
  )
  function isAttributeSelected(attribute: ShiftAttribute) {
    return selectedAttributes.has(attribute.type)
  }
  const selectedCertifications = useMemo(
    () => new Set(role.requiredCertifications),
    [role.requiredCertifications],
  )
  function isCertificationSelected(c: string) {
    return selectedCertifications.has(c as WorkerCertificationType)
  }

  const minPayRate = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })
  const STEPS = [
    {
      Component: RoleDetailStep,
      validate(): boolean {
        return (
          !!role.roleName &&
          !!role.roleDescription &&
          role.defaultPayRate! >= minPayRate &&
          !isRoleNameDupeInSameLocation({
            roles,
            newRoleName: role.roleName,
            newRoleLocationId: role.locationId,
            currentRoleId,
          }) &&
          (!hotSettings?.enableRegionalAccessPhase1 || !!role.locationId)
        )
      },
    },
    ...(hotSettings?.allowStoringRequiredAttributeLevel
      ? [
          {
            Component: RoleRequiredAttributeLevelsStep,
            validate(): boolean {
              return true
            },
          },
        ]
      : []),
    {
      Component: RoleResponsibilityStep,
      validate(): boolean {
        const otherSelected = role.requiredAttributes?.some(
          (ra) => ra.type === `other_${RoleAttributeCategory.Responsibilities}`,
        )
        const otherProvided =
          role.freeformAttributes &&
          role.freeformAttributes[RoleAttributeCategory.Responsibilities]
        if (otherSelected && !otherProvided) {
          return false
        }
        return true
      },
    },
    {
      Component: RoleOtherAttributesStep,
      validate(): boolean {
        const hasLifting = role.requiredAttributes?.some(
          (ra) => ra.category === RoleAttributeCategory.Lifting,
        )
        if (!hasLifting && !hotSettings?.allowStoringRequiredAttributeLevel) {
          return false
        }
        const hasForklift = role.requiredAttributes?.some(
          (ra) => ra.category === RoleAttributeCategory.Forklift,
        )
        if (hasForklift && !forkliftCertificationInteracted) {
          return false
        }
        const otherSelected = role.requiredAttributes?.some(
          (ra) => ra.type === `other_${RoleAttributeCategory.Equipment}`,
        )
        const otherProvided =
          role.freeformAttributes &&
          role.freeformAttributes[RoleAttributeCategory.Equipment]
        if (otherSelected && !otherProvided) {
          return false
        }
        return true
      },
    },
    {
      Component: RoleGearStep,
      validate(): boolean {
        return !!role.requiredAttire
      },
    },
  ]

  const atEnd = step === STEPS.length - 1 || (fromPrevious && step === 0)
  const { Component: RoleStepComponent, validate } = STEPS[step]
  const valid = validate()
  function onContinue(forceNext = false) {
    if (!valid) {
      return
    }
    if (atEnd && !forceNext) {
      // Remove "other" types & requiredAttrLevel attributes
      const scrubbedRequiredAttributes = role.requiredAttributes?.filter(
        (ra) =>
          !!roleAttributes?.find(
            (a) =>
              a.type === ra.type &&
              (!hotSettings?.allowStoringRequiredAttributeLevel ||
                !a.allowOpsLeveling),
          ),
      )
      // Remove entries for which "other" is no longer selected
      const scrubbedFreeformAttributes = !role.freeformAttributes
        ? undefined
        : Object.fromEntries(
            Object.entries(role.freeformAttributes).filter(([key]) =>
              role.requiredAttributes?.some((ra) => ra.type === `other_${key}`),
            ),
          )

      // Remove OSHA_FORKLIFT cert if forklift type attribute is not selected
      const hasForkliftAttribute = role.requiredAttributes?.some(
        (attribute) => attribute.category === RoleAttributeCategory.Forklift,
      )

      // Add training id if needed
      const requiredTrainingIds =
        isGMPTrainingSelected && gmpTrainingId ? [gmpTrainingId] : []

      const scrubbedRequiredCertfications = hasForkliftAttribute
        ? role.requiredCertifications
        : role.requiredCertifications?.filter(
            (certification) =>
              certification !== WorkerCertificationType.OSHA_FORKLIFT,
          )
      const scrubbedRole: CreateRoleData = {
        ...role,
        extraBGCRequirement:
          role.extraBGCRequirement || company.extraBGCRequirement,
        roleName: role.roleName || '',
        roleDescription: role.roleDescription || '',
        defaultPayRate:
          role.defaultPayRate !== undefined && role.defaultPayRate >= 0
            ? role.defaultPayRate
            : PAY_RATE_DEFAULT,
        requiredAttire: role.requiredAttire || '',
        requiredTrainingIds,
        requiredAttributes: scrubbedRequiredAttributes,
        requiredCertifications: scrubbedRequiredCertfications,
        freeformAttributes: scrubbedFreeformAttributes,
        locationId:
          role.locationId === COMPANY_WIDE_ID ? undefined : role.locationId,
      }

      if (isEdit) {
        const scrubbedEditRole = {
          ...scrubbedRole,
          genderPreference:
            scrubbedRole.genderPreference === undefined
              ? null
              : scrubbedRole.genderPreference,
          locationId: undefined, // we do not allow updating location after creation
        }
        onConfirmEdit && onConfirmEdit(scrubbedEditRole)
        return
      }
      onConfirmCreate && onConfirmCreate(scrubbedRole)
    } else {
      setStep(step + 1)
    }
  }
  function onPrevious() {
    if (step === 0) {
      onCancel()
    } else {
      setStep(step - 1)
    }
  }

  function addAttribute(attribute: ShiftAttribute) {
    setRole({
      requiredAttributes: (role.requiredAttributes || []).concat([
        { type: attribute.type, category: attribute.category },
      ]),
    })
  }

  function setRequiredAttributeLevels(
    requiredAttributeLevels: RequiredAttributeLevel[],
  ) {
    setRole({
      requiredAttributeLevels,
    })
  }

  function removeAttribute(attribute: ShiftAttribute) {
    setRole({
      requiredAttributes: role.requiredAttributes?.filter(
        (ra) => ra.type !== attribute.type,
      ),
    })
  }

  function toggleAttribute(attribute: ShiftAttribute) {
    if (isAttributeSelected(attribute)) {
      removeAttribute(attribute)
    } else {
      addAttribute(attribute)
    }
  }

  function addCertification(c: string) {
    setRole({
      requiredCertifications: (role.requiredCertifications || []).concat([
        c as WorkerCertificationType,
      ]),
    })
  }

  function removeCertification(c: string) {
    setRole({
      requiredCertifications: role.requiredCertifications?.filter(
        (rc) => rc !== c,
      ),
    })
  }

  function toggleCertification(c: string) {
    if (isCertificationSelected(c)) {
      removeCertification(c)
    } else {
      addCertification(c)
    }
  }

  if (!roleAttributes || !certifications) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxxl} />
      </Row>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '70vh',
      }}
    >
      <div>
        <RoleStepComponent
          role={role}
          setRole={setRole}
          previousRoleId={previousRoleId}
          setPreviousRoleId={setPreviousRoleId}
          attributes={roleAttributes}
          certifications={certifications}
          toggleAttribute={toggleAttribute}
          setRequiredAttributeLevels={setRequiredAttributeLevels}
          isAttributeSelected={isAttributeSelected}
          toggleCertification={toggleCertification}
          isCertificationSelected={isCertificationSelected}
          forkliftCertificationInteracted={forkliftCertificationInteracted}
          setForkliftCertificationInteracted={
            setForkliftCertificationInteracted
          }
          isModalOpen={isModalOpen}
          closeUploadVideoModal={closeUploadVideoModal}
          openUploadVideoModal={openUploadVideoModal}
          onUploadSuccess={onUploadSuccess}
          setSelectedVideoIds={setSelectedVideoIds}
          selectedVideoIds={selectedVideoIds}
          requiredForAllTrainingVideos={requiredForAllTrainingVideos}
          trainingVideos={trainingVideos}
          nextStep={() => onContinue(true)}
          isEdit={isEdit}
          isGMPTrainingSelected={isGMPTrainingSelected}
          setIsGMPTrainingSelected={setIsGMPTrainingSelected}
          minHourlyPayRate={minPayRate}
          company={company}
          hotSettings={hotSettings}
          currentRoleId={currentRoleId}
        />
      </div>
      <div>
        <Divider />
        <Row justifyBetween alignCenter mt={theme.space.sm}>
          <Button variant={ButtonVariant.OUTLINED} onClick={onPrevious}>
            {step === 0 ? 'Cancel' : 'Previous'}
          </Button>
          <Button
            variant={ButtonVariant.FILLED}
            onClick={() => onContinue()}
            loading={submitLoading}
            disabled={!valid}
          >
            {atEnd ? (isEdit ? 'Save' : 'Create') : 'Continue'}
          </Button>
        </Row>
      </div>
    </div>
  )
}
