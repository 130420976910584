import {
  Tab,
  TabPanel,
  Tabs,
  TabsContainer,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { useState } from 'react'
import { Row } from '../base'
import { ScheduleDetailsInvitesTab } from './ScheduleDetailsInvitesTab'
import { ScheduleDetailsShiftsTab } from './ScheduleDetailsShiftsTab'
import { ScheduleDetailsWeeklyScheduleTab } from './ScheduleDetailsWeeklyScheduleTab'
import { ScheduleDetailsWorkerTab } from './ScheduleDetailsWorkerTab'

interface Props {
  company: Company
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}

export const ScheduleDetailTabs = ({ company, shiftRequestParent }: Props) => {
  const [currentTab, setCurrentTab] = useState(0)

  const TABS = [
    {
      key: 'shifts',
      label: 'Shifts',
      Component: (
        <ScheduleDetailsShiftsTab
          company={company}
          shiftRequestParent={shiftRequestParent}
        />
      ),
    },
    {
      key: 'workers',
      label: 'Worker management',
      Component: (
        <ScheduleDetailsWorkerTab
          shiftRequestParent={shiftRequestParent}
          companyId={company.companyId}
        />
      ),
    },
    {
      key: 'schedule',
      label: 'Weekly worker schedule',
      Component: (
        <ScheduleDetailsWeeklyScheduleTab
          companyId={company.companyId}
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
    {
      key: 'invitations',
      label: 'Schedule invitations',
      Component: (
        <ScheduleDetailsInvitesTab
          shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        />
      ),
    },
  ]

  return (
    <>
      <TabsContainer>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
        >
          {TABS.map((t, index) => (
            <Tab
              key={t.label}
              label={
                <Row>
                  <Text
                    variant="h5"
                    color={
                      currentTab === index
                        ? theme.colors.MidnightBlue
                        : theme.colors.Grey60
                    }
                  >
                    {t.label}
                  </Text>
                </Row>
              }
            />
          ))}
        </Tabs>
      </TabsContainer>
      {TABS.map(({ Component }, i) => (
        <TabPanel value={currentTab} index={i} key={`schedule_tab_${i}`}>
          {Component}
        </TabPanel>
      ))}
    </>
  )
}
