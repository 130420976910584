import { LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useMemo, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { assetNameType } from '../../assets/svg/SvgIconProps'
import { useWorkerCommunications } from '../../hooks/useWorkerCommunications'
import {
  MessageProvider,
  MessageSource,
  WorkerCommunication,
  WorkerCommunicationMedium,
  WorkerInfoForCommunication,
} from '../../types/worker-communications'
import { formatTimeDistanceToNow } from '../../utils/dateUtils'
import { Player } from '../AudioPlayer/AudioPlayer'
import { Avatar, Icon, Row } from '../base'
import { CircleDiv } from '../base/Icon'

interface WorkerCommunicationsProps {
  workerId: string
  embedded?: boolean
  expanded?: boolean
  filters?: { [key: string]: boolean }
}

function getColorForMessageType(workerCommunication: WorkerCommunication) {
  if (workerCommunication.messageSource === MessageSource.WORKER) {
    return theme.colors.White
  }
  if (workerCommunication.messageSource === MessageSource.TRABA) {
    if (workerCommunication.bugleBlastId) {
      return theme.colors.Violet20
    }
    if (workerCommunication.messageProvider === MessageProvider.OPENPHONE) {
      return theme.colors.Blue10
    }
  }
  return theme.colors.Grey10
}

function getIconNameForMessageType(
  workerCommunication: WorkerCommunication,
): assetNameType {
  if (workerCommunication.messageProvider === MessageProvider.OPENPHONE) {
    if (
      workerCommunication.medium === WorkerCommunicationMedium.OPENPHONE_CALL
    ) {
      return 'openphoneCall'
    }
    return 'openphone'
  }

  if (workerCommunication.bugleBlastId) {
    return 'bugle'
  }

  return 'systemMessage'
}

interface WorkerCommunicationRowProps {
  communication: WorkerCommunication & WorkerInfoForCommunication
}

export function WorkerCommunicationRow({
  communication,
}: WorkerCommunicationRowProps) {
  const color = getColorForMessageType(communication)
  const iconName = getIconNameForMessageType(communication)
  const fromTraba = communication.messageSource === MessageSource.TRABA
  const workerPhotoUrl = communication.photoUrl

  const parsedMedia = communication.media ? JSON.parse(communication.media) : []
  const mediaObj = parsedMedia ? parsedMedia[0] : undefined
  const isOpenphoneCall =
    communication.medium === WorkerCommunicationMedium.OPENPHONE_CALL
  const hasAudioMedia =
    isOpenphoneCall &&
    mediaObj &&
    mediaObj.type &&
    mediaObj.type === 'audio/mpeg' &&
    mediaObj.url
  const mediaUrl = hasAudioMedia ? mediaObj.url : ''

  return (
    <Row
      px={theme.space.xs}
      py={theme.space.xs}
      mx={theme.space.xxs}
      style={{
        borderRadius: theme.border.radius,
        justifyContent:
          communication.messageSource === MessageSource.WORKER
            ? 'flex-end'
            : 'flex-start',
      }}
      alignCenter
    >
      <Row flexCol style={{ maxWidth: '70%' }}>
        <Row>
          {fromTraba && (
            <Row mr={theme.space.xxs}>
              <CircleDiv size={50} backgroundColor={color}>
                <Icon name={iconName} type="svg" size={24} />
              </CircleDiv>
            </Row>
          )}
          <Row
            style={{
              borderRadius: theme.border.radius,
              border: fromTraba ? 'none' : `1px solid ${theme.colors.Blue10}`,
              backgroundColor: color,
              minWidth: 140,
            }}
            px={theme.space.xs}
            py={theme.space.xs}
            mr={theme.space.xxs}
          >
            {mediaUrl ? (
              <Row style={{ width: 500 }}>
                <Player mediaUrl={mediaUrl} workerId={communication.workerId} />
              </Row>
            ) : communication.body ? (
              <Text>{communication.body}</Text>
            ) : (
              <Text> status: {communication.status}</Text>
            )}
          </Row>
          {!fromTraba && !isOpenphoneCall && workerPhotoUrl && (
            <div>
              <Avatar
                src={workerPhotoUrl}
                size={50}
                round
                name={communication.firstName}
              />
            </div>
          )}
          {!fromTraba && isOpenphoneCall && (
            <Row ml={theme.space.xxs}>
              <CircleDiv
                size={50}
                backgroundColor={color}
                style={{ border: `1px solid ${theme.colors.Grey20}` }}
              >
                <Icon name={iconName} type="svg" size={24} />
              </CircleDiv>
            </Row>
          )}
        </Row>
        <Row
          style={{
            justifyContent:
              communication.messageSource === MessageSource.WORKER
                ? 'flex-start'
                : 'flex-end',
          }}
          mx={theme.space.xxs}
        >
          <Text>
            {formatTimeDistanceToNow(new Date(communication.createdAt))}
          </Text>
        </Row>
      </Row>
    </Row>
  )
}

export const WorkerCommunicationsWorkerMessages = ({
  workerId,
  embedded,
  expanded,
  filters,
}: WorkerCommunicationsProps) => {
  const { isLoading, workerCommunications, loadMore, hasNextPage } =
    useWorkerCommunications(workerId, filters)

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  /** Have newer messages at the bottom */
  const sortedWorkerCommunications = useMemo(
    () =>
      workerCommunications?.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      ),
    [workerCommunications],
  )

  useEffect(() => {
    window.analytics.track('Worker Communications Messages Viewed', {
      workerId,
    })
  }, [workerId])

  return (
    <Row
      mb={theme.space.xs}
      py={theme.space.xxs}
      style={{
        ...(embedded
          ? {
              border: `1px solid ${theme.colors.Grey20}`,
            }
          : {
              overflowY: 'hidden',
              border: `1px solid ${theme.colors.Grey20}`,
              backgroundColor: theme.colors.White,
            }),
        overflowY: 'hidden',
        height: '100%',
      }}
      fullWidth
    >
      <div
        ref={scrollContainerRef}
        id="scrollableDiv"
        style={{
          overflowY: 'auto',
          height: expanded ? '60vh' : 500,
          display: 'flex',
          flexDirection: 'column-reverse',
          width: '100%',
        }}
      >
        <InfiniteScroll
          dataLength={sortedWorkerCommunications?.length ?? 0}
          next={loadMore}
          hasMore={!!hasNextPage}
          loader={<LoadingSpinner />}
          scrollableTarget="scrollableDiv"
          inverse={true}
          scrollThreshold={0.9} // Trigger load when 90% of the list is visible
        >
          {sortedWorkerCommunications &&
          sortedWorkerCommunications.length > 0 ? (
            sortedWorkerCommunications.map((communication) => (
              <WorkerCommunicationRow
                communication={communication}
                key={`worker-communications-messages-${communication.id}`}
              />
            ))
          ) : isLoading ? (
            <LoadingSpinner />
          ) : (
            <Row
              pt={theme.space.xs}
              px={theme.space.xs}
              alignCenter
              justifyCenter
            >
              <Text>No communications found that match this criteria</Text>
            </Row>
          )}
        </InfiniteScroll>
      </div>
    </Row>
  )
}
