import { THREE_WEEKS_IN_DAYS } from '@traba/consts'
import { Text } from '@traba/react-components'
import { Company, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { isRecurringShiftRequest } from '@traba/utils'
import { addDays } from 'date-fns'
import { useCallback, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { EditScheduleCancel } from './EditSchedule/EditScheduleCancel'
import { EditScheduleEndDate } from './EditSchedule/EditScheduleEndDate'
import {
  EDIT_OPTIONS,
  EditScheduleEntry,
} from './EditSchedule/EditScheduleEntry'
import { EditScheduleNewRole } from './EditSchedule/EditScheduleNewRole'
import { EditScheduleRemoveRoles } from './EditSchedule/EditScheduleRemoveRole'
import { EditScheduleRole } from './EditSchedule/EditScheduleRole'

interface Props {
  handleClose: () => void
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  company: Company
  isOpen: boolean
}

export const EditScheduleModal = (props: Props) => {
  const { handleClose, shiftRequestParent, isOpen, company } = props
  const { shiftRequestParentId, companyId, shiftRequests } = shiftRequestParent
  const [selectedOption, setSelectedOption] = useState<
    EDIT_OPTIONS | undefined
  >()
  const { refetch: refetchParents } =
    useShiftRequestParent(shiftRequestParentId)
  const { rawShifts, refetch } = useCompanyShifts(
    {
      shiftRequestParentIds: [shiftRequestParentId],
      companyId,
      startAfter: new Date(),
      endBefore: addDays(new Date(), THREE_WEEKS_IN_DAYS),
    },
    true,
  )
  const shifts = rawShifts?.pages.map((page) => page?.data).flat()

  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    (sr) => sr.schedules.some((s) => s.isRecurringSchedule),
  )

  const recurringShiftRequests = shiftRequests.filter(isRecurringShiftRequest)

  const getContent = useCallback(() => {
    if (!firstRecurringShiftRequest) {
      return <Text>No recurring shift request found in this schedule</Text>
    }

    const onClose = () => {
      setSelectedOption(undefined)
      refetch()
      refetchParents()
      handleClose()
    }

    switch (selectedOption) {
      case EDIT_OPTIONS.EXTEND_SCHEDULE_OR_MODIFY_END_DATE:
        return (
          <EditScheduleEndDate
            timezone={shiftRequests[0].timezone}
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
          />
        )

      case EDIT_OPTIONS.MODIFY_AN_EXISTING_ROLE:
        return (
          <EditScheduleRole
            shiftRequestParent={shiftRequestParent}
            company={company}
            shiftRequests={recurringShiftRequests}
            firstRecurringShiftRequest={firstRecurringShiftRequest}
            onBack={() => setSelectedOption(undefined)}
            onSuccess={onClose}
            shifts={shifts}
          />
        )

      case EDIT_OPTIONS.ADD_A_NEW_ROLE:
        return (
          <EditScheduleNewRole
            shiftRequestParent={shiftRequestParent}
            company={company}
            firstRecurringShiftRequest={firstRecurringShiftRequest}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
            onSuccess={onClose}
          />
        )

      case EDIT_OPTIONS.REMOVE_ROLES:
        return (
          <EditScheduleRemoveRoles
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
          />
        )
      case EDIT_OPTIONS.CANCEL_SCHEDULE:
        return (
          <EditScheduleCancel
            shiftRequestParent={shiftRequestParent}
            onSuccess={onClose}
            onBack={() => setSelectedOption(undefined)}
            shifts={shifts}
          />
        )

      default:
        return (
          <EditScheduleEntry
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )
    }
  }, [
    company,
    firstRecurringShiftRequest,
    selectedOption,
    shiftRequestParent,
    shiftRequests,
    shifts,
  ])

  return (
    <Modal
      handleClose={() => {
        setSelectedOption(undefined)
        handleClose()
      }}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={selectedOption ?? 'Edit schedule'}
    >
      {getContent()}
    </Modal>
  )
}
