import { Warning } from '@mui/icons-material'
import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import React from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Field } from 'src/hooks/useSearchInputItems'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { Button, Row } from '../../base'
import { IMenuItem } from '../../base/Select/Select'
import InputMapper from '../../InputMapper/InputMapper'
import { CustomParams, SearchFormData } from '../hooks/useBugleForm'
import * as S from '../styles'

interface ParamsSectionProps {
  paramsSet: CustomParams
  toggleParamsSet: (event: React.MouseEvent<HTMLButtonElement>) => void
  fieldsToShow: Field[]
  form: SearchFormData
  regions: { displayName: string; regionId: string }[]
  isFormValid: boolean
  workersSearchResult: PopulatedWorker[]
  handleClear: () => void
  handleRegionSelect: (selectedItems: IMenuItem[]) => void
  setForm: (data: SearchFormData) => void
  setShowDrawer: (show: boolean) => void
  shift: Shift
}

const ParamsSection: React.FC<ParamsSectionProps> = ({
  toggleParamsSet,
  setShowDrawer,
  handleClear,
  paramsSet,
  form,
  setForm,
  fieldsToShow,
  handleRegionSelect,
  regions,
  workersSearchResult,
  isFormValid,
  shift,
}) => {
  return (
    <div>
      <SectionHeader
        paramsSet={paramsSet}
        toggleParamsSet={toggleParamsSet}
        setShowDrawer={setShowDrawer}
        handleClear={handleClear}
        isFormValid={isFormValid}
        workersSearchResult={workersSearchResult}
        form={form}
        handleRegionSelect={handleRegionSelect}
        regions={regions}
      />
      {paramsSet === 'custom' && (
        <CustomForm
          fieldsToShow={fieldsToShow}
          form={form}
          setForm={setForm}
          shift={shift}
        />
      )}
      {paramsSet === 'backfill' && (
        <S.BackfillPresetSection>
          <Text variant="label" mr={theme.space.sm}>
            This preset will search workers to fill the shift. It considers
            various factors like distance, availability, previous experience
            with the same company, and more.
          </Text>
        </S.BackfillPresetSection>
      )}
    </div>
  )
}

const SectionHeader = ({
  paramsSet,
  toggleParamsSet,
  handleClear,
  isFormValid,
  setShowDrawer,
  regions,
  form,
  handleRegionSelect,
}: {
  paramsSet: CustomParams
  toggleParamsSet: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleClear: () => void
  isFormValid: boolean
  workersSearchResult: PopulatedWorker[]
  setShowDrawer: (show: boolean) => void
  form: SearchFormData
  regions: { displayName: string; regionId: string }[]
  handleRegionSelect: (selectedItems: IMenuItem[]) => void
}) => {
  const getVariant = (type: string) =>
    type === paramsSet ? ButtonVariant.FILLED : ButtonVariant.OUTLINED
  return (
    <Row justifyBetween style={{ height: 50 }} alignCenter>
      <Row alignCenter>
        <SearchSelect
          options={regions.map((r) => ({
            label: r.displayName,
            value: r.regionId,
          }))}
          selectedItems={
            form && form.regionIds?.length > 0 ? form.regionIds : []
          }
          handleSelectMultiple={handleRegionSelect}
          label={'Target regions'}
          onlyShowLabel
          multiple
          width={200}
        />
        {!form.regionIds?.length && (
          <Text variant="error" ml={theme.space.xs}>
            Set the target regions
          </Text>
        )}
      </Row>
      <Row style={{ gap: theme.space.xxs }}>
        <Button
          data-type="backfill"
          variant={getVariant('backfill')}
          onClick={toggleParamsSet}
        >
          Backfill
        </Button>
        <Button
          data-type="custom"
          variant={getVariant('custom')}
          onClick={toggleParamsSet}
        >
          Custom Search
        </Button>

        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => setShowDrawer(true)}
          disabled={paramsSet !== 'custom'}
        >
          Edit fields
        </Button>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={handleClear}
          disabled={
            paramsSet !== 'custom' || (paramsSet === 'custom' && !isFormValid)
          }
        >
          Clear
        </Button>
      </Row>
    </Row>
  )
}

interface CustomFormProps {
  fieldsToShow: Field[]
  form: SearchFormData
  setForm: (data: SearchFormData) => void
  shift: Shift
}

const CustomForm: React.FC<CustomFormProps> = ({
  fieldsToShow,
  form,
  setForm,
  shift,
}) => {
  const handleForm = (field: string, value: any) => {
    let parsedValue: any = value

    if (
      field === 'maxDistanceFromShiftId' ||
      field === 'minDistanceFromShiftId'
    ) {
      parsedValue = parseFloat(value)
    } else if (field === 'hasWorkedWithCompanyId' && value === true) {
      parsedValue = shift.companyId
    }

    setForm({ ...form, [field]: parsedValue })
  }

  return (
    <S.FormContainer>
      {fieldsToShow.length ? (
        <InputMapper
          inputFields={fieldsToShow}
          values={form}
          onChange={handleForm}
        />
      ) : (
        <Text>
          <Warning fontSize="small" /> Select some search fields using "Edit
          fields"
        </Text>
      )}
    </S.FormContainer>
  )
}

export default ParamsSection
