import { Tooltip } from '@mui/material'
import { Anchor, TextVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PhoneNumberStatus } from '@traba/types'
import { formatPhoneNumber } from 'src/utils/stringUtils'
import { CopyTextIcon } from './base'

interface PhoneNumberWithStatusProps {
  phoneNumber: string
  phoneNumberStatus?: PhoneNumberStatus
  variant?: TextVariant
  textStyle?: React.CSSProperties
}

export function PhoneNumberWithStatus({
  phoneNumber,
  phoneNumberStatus = PhoneNumberStatus.VERIFIED,
  variant = 'link',
  textStyle = {},
}: PhoneNumberWithStatusProps) {
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber, true)
  const tooltipText =
    phoneNumberStatus === PhoneNumberStatus.VERIFIED ? '' : phoneNumberStatus

  const textVariant =
    phoneNumberStatus === PhoneNumberStatus.VERIFIED ? variant : 'error'

  return (
    <Tooltip
      title={tooltipText}
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, -theme.space.xxs] },
          },
        ],
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Anchor
          openInNewTab={false}
          href={`tel:${formattedPhoneNumber}`}
          variant={textVariant}
          style={textStyle}
        >
          {formattedPhoneNumber}
        </Anchor>
        <CopyTextIcon textToCopy={formattedPhoneNumber} />
      </div>
    </Tooltip>
  )
}
