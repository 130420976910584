import {
  EditMemberModalContent,
  EditMemberModalContentUpdateUser,
  LoadingSpinner,
} from '@traba/react-components'
import { User } from '@traba/types'
import {
  assignedLocationIdsForMember,
  getStartOfRecentFiveMinBlock,
} from '@traba/utils'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useRegionLocationMap } from 'src/hooks/useCompanyLocations'
import { useCompanyShiftsLocationsForSupervisor } from 'src/hooks/useCompanyShiftsLocationsForSupervisor'
import {
  useCompanyMemberIdToMemberMap,
  useCompanyUserMutations,
  useCompanyUsers,
} from 'src/hooks/useCompanyUsers'
import { useValidRegionsMap } from 'src/hooks/useRegions'

export type EditUserModalProps = {
  user: User
  isOpen: boolean
  handleClose: () => void
}

export function EditUserModal({
  user,
  handleClose,
  isOpen,
}: EditUserModalProps) {
  const { companyId = '' } = user
  const { activeCompanyUsers = [], isLoading: isLoadingUsers } =
    useCompanyUsers(companyId)
  const { updateMember, isUpdateMemberLoading } =
    useCompanyUserMutations(companyId)
  const { memberIdToMemberMap, isLoading: isLoadingMemberMap } =
    useCompanyMemberIdToMemberMap(companyId)
  const { regionMap, isLoading: isLoadingRegionMap } = useValidRegionsMap()
  const { activeRegionsWithLocationsMap, isLoading: loadingRegionLocationMap } =
    useRegionLocationMap(companyId)

  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { locations, isLoading: isLoadingLocationsForFutureShifts } =
    useCompanyShiftsLocationsForSupervisor({
      companyId,
      supervisorId: user.uid,
      minShiftStartTime: startOfLastFiveMinBlock,
    })

  const onUpdateMember = useCallback(
    async (updatedMemberProps: EditMemberModalContentUpdateUser) => {
      updateMember(
        {
          uid: user.uid,
          ...updatedMemberProps,
          locationIds:
            updatedMemberProps.locationIds ||
            assignedLocationIdsForMember(user),
          userAccessLevel:
            updatedMemberProps.userAccessLevel || user.userAccessLevel,
        },
        {
          onSettled: handleClose,
        },
      )
      window.analytics.track(`User Saved Member Role Change`, {
        member: user,
        ...updatedMemberProps,
      })
    },
    [user, updateMember, handleClose],
  )

  const isLoadingMemberOrLocations =
    isLoadingUsers ||
    loadingRegionLocationMap ||
    isLoadingRegionMap ||
    isLoadingLocationsForFutureShifts ||
    isLoadingMemberMap

  return (
    <Modal size={MODAL_SIZE.MEDIUM} isOpen={isOpen} handleClose={handleClose}>
      {isLoadingMemberOrLocations ? (
        <LoadingSpinner />
      ) : (
        <EditMemberModalContent
          member={user}
          handleClose={handleClose}
          onUpdateMember={onUpdateMember}
          loading={isUpdateMemberLoading}
          replacementMembers={activeCompanyUsers}
          locationsForFutureShifts={locations}
          regionMap={regionMap}
          regionToLocationsMap={activeRegionsWithLocationsMap}
          memberIdToMemberMap={memberIdToMemberMap}
        />
      )}
    </Modal>
  )
}
