import { Time } from '@internationalized/date'
import { CircularProgress } from '@mui/material'
import { IMenuItem, Input, TableRow, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { addWeeks, formatDistanceToNow, startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import {
  Button,
  Col,
  CopyTextIcon,
  Link,
  Row,
  Select,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import Pagination from 'src/components/base/Pagination/Pagination'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import Toggle from 'src/components/base/Toggle'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { useWorkersOnWatchlist } from 'src/hooks/useWorkersOnWatchlist'
import { formatPhoneNumber } from 'src/utils/stringUtils'
import { WorkerRequirementStatusIndicator } from './WorkerRequirementStatusIndicator'

const WORKER_WATCHLIST_PAGE_SIZE = 50
const workerWatchlistLimitOptions: IMenuItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
]

export const WorkerWatchlist = () => {
  const today = startOfDay(new Date())
  const [until, setUntil] = useState(addWeeks(today, 1))
  const [showBgcIncomplete, setBgcFilter] = useState(false)
  const [showStripeIncomplete, setStripeFilter] = useState(false)
  const [regionIds, setRegionIds] = useState<string[]>([])
  const [searchFieldText, setSearchFieldText] = useState<string>('')
  const [pageSize, setPageSize] = useState(WORKER_WATCHLIST_PAGE_SIZE)
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()
  const { isLoading, isFetching, isError, workersOnWatchlist, error } =
    useWorkersOnWatchlist(
      until,
      showBgcIncomplete,
      showStripeIncomplete,
      searchFieldText,
      regionIds,
      currentPage,
      pageSize,
    )

  const { regions = [], isLoading: regionsLoading } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker Name',
    },
    {
      key: 'requiredByTime',
      label: 'Required By',
      sortable: true,
    },
    {
      key: 'shiftsDone',
      label: 'Shifts Done',
      sortable: true,
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      key: 'workerId',
      label: 'Worker Id',
    },
    {
      key: 'stripeStatus',
      label: 'Stripe',
      sortable: true,
    },
    {
      key: 'backgroundCheckStatus',
      label: 'BGC',
      sortable: true,
    },
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workersOnWatchlist?.map((workerDetails) => ({
        key: workerDetails.id,
        cells: [
          {
            key: `${workerDetails.id}-name`,
            content: `${workerDetails.firstName} ${workerDetails.lastName}`,
            renderFn: () => {
              const fullName = `${workerDetails.firstName} ${workerDetails.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link to={`/workers/${workerDetails.id}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workerDetails.id}-nextImpactedShiftTime`,
            content: workerDetails.nextImpactedShiftTime,
            renderFn: () => (
              <Link
                to={`/field-monitor/${workerDetails.nextImpactedShiftId.toString()}`}
                target="_blank"
              >
                <Button
                  tooltipText={formatDistanceToNow(
                    workerDetails.nextImpactedShiftTime,
                    { addSuffix: true },
                  )}
                  style={{ padding: 0 }}
                  variant={ButtonVariant.TEXT}
                >
                  {new Date(
                    workerDetails.nextImpactedShiftTime,
                  ).toLocaleDateString('en-US') || 'N/A'}
                </Button>
              </Link>
            ),
            sortKey: Date.parse(
              new Date(workerDetails.nextImpactedShiftTime).toLocaleDateString(
                'en-US',
              ),
            ),
          },
          {
            key: `${workerDetails.id}-completedShifts`,
            content: workerDetails.completedShifts,
            renderFn: () => <Text>{workerDetails.completedShifts}</Text>,
            sortKey: workerDetails.completedShifts,
          },
          {
            key: `${workerDetails.id}-phone`,
            content: workerDetails.phoneNumber,
            renderFn: () => (
              <Text>
                {formatPhoneNumber(workerDetails.phoneNumber, true)}
                <CopyTextIcon textToCopy={workerDetails.phoneNumber} />
              </Text>
            ),
          },
          {
            key: `${workerDetails.id}-id`,
            content: workerDetails.id,
            renderFn: () => (
              <Text>
                {`${workerDetails.id.slice(0, 8)}...`}
                <CopyTextIcon textToCopy={workerDetails.id} />
              </Text>
            ),
          },
          {
            key: `${workerDetails.id}-stripeStatus`,
            content: workerDetails.stripeRequirementsIncomplete.toString(),
            renderFn: () =>
              workerDetails.stripeRequirementsIncomplete ? (
                <WorkerRequirementStatusIndicator requirementStatus="NOT_DONE" />
              ) : (
                <WorkerRequirementStatusIndicator requirementStatus="DONE" />
              ),
            sortKey: workerDetails.stripeRequirementsIncomplete.toString(),
          },
          {
            key: `${workerDetails.id}-bgcStatus`,
            content:
              workerDetails.backgroundCheckIncomplete.toString() || 'N/A',
            renderFn: () =>
              workerDetails.backgroundCheckIncomplete ? (
                <WorkerRequirementStatusIndicator
                  requirementStatus="NOT_DONE"
                  tooltipText={workerDetails.backgroundCheckStatus}
                />
              ) : (
                <WorkerRequirementStatusIndicator
                  requirementStatus="DONE"
                  tooltipText={workerDetails.backgroundCheckStatus}
                />
              ),
            sortKey:
              workerDetails.backgroundCheckIncomplete.toString() || 'N/A',
          },
        ],
      })) || []
    )
  }, [workersOnWatchlist])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Input
          containerStyle={{ margin: '0' }}
          type="text"
          leftIconName="search"
          placeholder="Search workers"
          value={searchFieldText}
          width="300px"
          onChange={(e) => setSearchFieldText(e.target.value)}
          onClear={() => setSearchFieldText('')}
        />
        <Toggle
          label={'Incomplete BGC'}
          buttonState={showBgcIncomplete}
          runOnChange={() => {
            setBgcFilter(!showBgcIncomplete)
            setCurrentPage(0)
          }}
          containerStyle={{ width: 150, justifyContent: 'center' }}
        />
        <Toggle
          label={'Incomplete Stripe'}
          buttonState={showStripeIncomplete}
          runOnChange={() => {
            setStripeFilter(!showStripeIncomplete)
            setCurrentPage(0)
          }}
          containerStyle={{ width: 150, justifyContent: 'center' }}
        />
        <DatePicker
          showTimeFieldInPopover={true}
          minDate={new Date()}
          setDate={(until) => {
            setUntil(until ?? new Date())
            setCurrentPage(0)
          }}
          isClearable={true}
          inlineLabel={true}
          label="Shifts Before"
          width={280}
          date={until}
          defaultTime={new Time(0, 0)}
        />
        <StateSearchSelect
          multiple
          options={regionsOptions}
          selectedItems={regionIds}
          handleSelectMultiple={setRegionIds}
          label={`Region${regionIds.length > 1 ? 's' : ''}`}
          width={260}
        />
        <Pagination
          dataSize={workersOnWatchlist?.length || 0}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          page={currentPage}
          pageSize={pageSize}
        />
        <Select
          label="Limit"
          value={pageSize.toString()}
          handleSelect={(v) => setPageSize(parseInt(v))}
          menuItems={workerWatchlistLimitOptions}
          containerStyle={{ marginLeft: theme.space.xs }}
        />
      </Row>
      {isLoading || regionsLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={1}
        />
      )}
      {isError && (
        <Text variant="error">
          Something went wrong: {(error as Error).message}
        </Text>
      )}
    </Col>
  )
}
