import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, ShiftRequestParent, ShiftStatus } from '@traba/types'
import { formatShiftDateWithTimezone, getShiftTimeString } from '@traba/utils'
import { addDays } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ONE_MONTH_IN_DAYS, useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { getPayRateString } from 'src/utils/stringUtils'
import { DataTable, Row, ShiftStatusBadge } from '../base'
import DateRangePicker from '../base/AriaDatePicker/DateRangePicker'

interface Props {
  company: Company
  shiftRequestParent: ShiftRequestParent
}
export const ScheduleDetailsShiftsTab = ({
  company,
  shiftRequestParent,
}: Props) => {
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [includeCanceled, setIncludeCanceled] = useState(true)
  const { rawShifts, hasNextPage, fetchNextPage, isFetching } =
    useCompanyShifts(
      {
        shiftRequestParentIds: [shiftRequestParent.shiftRequestParentId],
        companyId: company.companyId,
        startAfter: dateRange[0] ?? new Date(),
        endBefore: dateRange[1] ?? addDays(new Date(), ONE_MONTH_IN_DAYS),
      },
      true,
    )
  const shifts = rawShifts?.pages.map((page) => page?.data).flat()
  const shiftsToDisplay = useMemo(() => {
    if (includeCanceled) {
      return shifts
    }
    return shifts?.filter((shift) => shift.status !== ShiftStatus.CANCELED)
  }, [includeCanceled, shifts])
  const shiftsTableHeaders = [
    { label: 'Date', key: 'date', sortable: true },
    { label: 'Time', key: 'time' },
    { label: 'Roles', key: 'roles' },
    { label: 'Pay rate', key: 'payRate' },
    { label: 'Slots', key: 'slots' },
    { label: 'Status', key: 'status' },
  ]

  const shiftTableRows = useMemo(() => {
    return shiftsToDisplay?.map((shift) => ({
      key: shift.shiftId,
      cells: [
        {
          key: 'date',
          renderFn: () => (
            <Text>
              {formatShiftDateWithTimezone(shift.startTime, shift.timezone)}
            </Text>
          ),
        },
        {
          key: 'time',
          renderFn: () => (
            <Text>
              {getShiftTimeString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
            </Text>
          ),
        },
        {
          key: 'roles',
          renderFn: () => (
            <Row
              alignCenter
              onClick={() => {
                navigate(`/field-monitor/${shift.shiftId}`)
              }}
            >
              <Text>{shift.shiftRole}</Text>
              <SvgIcon name="link" />
            </Row>
          ),
        },
        {
          key: 'payRate',
          renderFn: () => (
            <Text>{getPayRateString(shift.payType, shift.payRate)}</Text>
          ),
        },
        {
          key: 'slots',
          renderFn: () => (
            <Text>{`${shift.slotsFilled} / ${shift.slotsRequested}`}</Text>
          ),
        },
        {
          key: 'status',
          renderFn: () => <ShiftStatusBadge shiftStatus={shift.status} />,
        },
      ],
    }))
  }, [shifts])

  return (
    <>
      <Row alignCenter justifyBetween>
        <DateRangePicker
          label="Date range"
          inlineLabel={true}
          dateRange={dateRange}
          setDateRange={setDateRange}
          granularity="day"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xs }}
        />
        <Toggle
          label={'Included canceled'}
          buttonState={includeCanceled}
          runOnChange={() => setIncludeCanceled((prev) => !prev)}
          containerStyle={{ justifyContent: 'center' }}
        />
      </Row>
      <DataTable
        initialSortByAscOrder={false}
        headers={shiftsTableHeaders}
        rows={shiftTableRows ?? []}
      />
      {dateRange[0] === null &&
        dateRange[1] === null &&
        (isFetching ? (
          <LoadingSpinner />
        ) : (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              hasNextPage && fetchNextPage()
            }}
          >
            load more
          </Button>
        ))}
    </>
  )
}
