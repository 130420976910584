import { AccountApprovalStatus, WorkerSearchParamsRaw } from '@traba/types'
import { subDays } from 'date-fns'
import { identity, pickBy } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import { useSessionStorage } from 'react-use'
import { useAttributes } from 'src/hooks/useAttributes'
import { useSearchInputItems } from 'src/hooks/useSearchInputItems'
import { useUserContext } from '../../../context/user/UserContext'
import { useHotSettings } from '../../../hooks/useHotSettings'
import { useRegions } from '../../../hooks/useRegions'
import { useSearchWorkers, SearchWorkersType } from '../../../hooks/useWorkers'

import { IMenuItem } from '../../base/Select/Select'
import { CommunicationMethod } from '../BugleDrawer'
import {
  BODY_LOCAL_STORAGE_KEY,
  SCREEN_LOCAL_STORAGE_KEY,
  TITLE_LOCAL_STORAGE_KEY,
} from '../sections/PushInput'
import { MESSAGE_LOCAL_STORAGE_KEY } from '../sections/SMSInput'
import { NewBugleSection, SectionsOpenState } from '../tabs/NewBugleScreen'

export interface SearchFormData {
  [key: string]: any
}

export const DEFAULT_PAGE_SIZE = 50
export const BUGLE_SMS_DAILY_LIMIT_PER_WORKER = 8

export type CustomParams = 'custom' | 'backfill'

export function buildBugleSearchParams(
  form: SearchFormData,
  shiftId: string,
  savedSearchId?: string,
  companyId?: string,
  communicationMethod?: CommunicationMethod,
  customRateLimit?: number,
): WorkerSearchParamsRaw {
  const searchParams: WorkerSearchParamsRaw = {
    ...form,
    savedSearchId,
    targetShiftId: shiftId,
    isOnShiftId: form.isOnShiftId ? shiftId : undefined,
    isOnWaitlistForShiftId: form.isOnWaitlistForShiftId ? shiftId : undefined,
    isAvailableForShiftId: form.isAvailableForShiftId ? shiftId : undefined,
    isInvitedToShiftId: form.isInvitedToShiftId ? shiftId : undefined,
    hasValidConnectionsWithCompanyId: companyId,
    ...(communicationMethod === CommunicationMethod.BackfillCall
      ? { availableToBackfillCallNow: true }
      : {}),
    ...(communicationMethod === CommunicationMethod.SMS
      ? {
          hasNotBlockedInternalPhoneNumbers: true,
          last24HoursBugleSMSLimit:
            customRateLimit ?? BUGLE_SMS_DAILY_LIMIT_PER_WORKER,
        }
      : {}),
  }
  return pickBy(searchParams, identity)
}

const FORM_SESSION_STORAGE_KEY = 'bugle_form'

const useBugleForm = ({
  shiftId,
  sectionsOpenState,
  setSectionsOpenState,
  paramsSet,
  regionId,
  companyId,
  communicationMethod,
  pageSize = DEFAULT_PAGE_SIZE,
}: {
  shiftId: string
  regionId: string
  companyId: string
  paramsSet: CustomParams
  communicationMethod?: CommunicationMethod
  sectionsOpenState: SectionsOpenState
  setSectionsOpenState: React.Dispatch<React.SetStateAction<SectionsOpenState>>
  pageSize?: number
}) => {
  const defaultValues = {
    isAvailableForShiftId: shiftId,
    lastActiveAtFrom: subDays(new Date(), 60),
    accountStatuses: [
      { value: AccountApprovalStatus.Approved, label: 'Approved' },
    ],
  }

  // Store state only for specific shift
  const [form, setForm] = useSessionStorage<SearchFormData>(
    `${FORM_SESSION_STORAGE_KEY}_${shiftId}`,
    defaultValues,
  )
  const { regions = [], isLoading: isLoadingRegions } = useRegions()
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { state } = useUserContext()
  const { bugleInputFields } = useSearchInputItems()

  const {
    attributes: roleAttributes,
    isFetched,
    isLoading: isLoadingAttributes,
  } = useAttributes()

  const [hasProcessedEligibility, setHasProcessedEligibility] =
    useState<boolean>(false)

  useEffect(() => {
    if (regions.length > 0) {
      const selectedRegion = regions.find((r) => r.regionId === regionId)
      if (selectedRegion) {
        setForm((prevForm: SearchFormData) => ({
          ...prevForm,
          regionIds: [
            {
              label: selectedRegion.displayName,
              value: selectedRegion.regionId,
            },
          ],
        }))
      }
    }
  }, [regions, regionId, setForm])

  const {
    isLoading: isFetchingWorkers,
    error,
    searchWorkers,
    workersSearchResult,
    reset,
    currentPage,
    totalFound,
  } = useSearchWorkers()

  const handleClearParam = useCallback(
    (key: string) => {
      setForm((prevForm: SearchFormData) => ({ ...prevForm, [key]: undefined }))
    },
    [setForm],
  )

  const handleRegionSelect = (items: IMenuItem[]) => {
    setForm((prevForm: SearchFormData) => ({ ...prevForm, regionIds: items }))
  }

  const handleSearch = useCallback(
    (direction: 'next' | 'previous' | 'initial') => {
      const savedSearchId =
        paramsSet === 'backfill'
          ? communicationMethod === CommunicationMethod.BackfillCall
            ? hotSettings?.presetSavedSearchIds?.backfillCall
            : hotSettings?.presetSavedSearchIds?.backfill
          : undefined
      const customRateLimit = hotSettings?.bugleSmsDailyLimitPerWorker

      const searchData = buildBugleSearchParams(
        form,
        shiftId,
        savedSearchId,
        companyId,
        communicationMethod,
        customRateLimit,
      )

      searchWorkers({
        searchData,
        direction,
        limit: pageSize,
        type: SearchWorkersType.raw,
        cacheKey: 'bugle-search-form',
      })
      setSectionsOpenState({
        ...sectionsOpenState,
        [NewBugleSection.SearchWorkers]: true,
        [NewBugleSection.ViewAndEdit]: true,
      })
      setHasProcessedEligibility(false)
      window.analytics.track('Bugle Search Called', {
        searchData,
        initiatedBy: state.userProfile?.email,
      })
    },
    [
      paramsSet,
      communicationMethod,
      hotSettings?.presetSavedSearchIds?.backfillCall,
      hotSettings?.presetSavedSearchIds?.backfill,
      form,
      shiftId,
      companyId,
      searchWorkers,
      pageSize,
      setSectionsOpenState,
      sectionsOpenState,
      state.userProfile?.email,
    ],
  )

  const isFormValid = bugleInputFields.some(({ field }) => {
    return !!form[field]
  })

  const handleClear = () => {
    setForm({
      regionIds: form.regionIds,
    })
    reset()
    setSectionsOpenState({
      ...sectionsOpenState,
      [NewBugleSection.SearchWorkers]: true,
      [NewBugleSection.ViewAndEdit]: false,
    })
    localStorage.setItem(MESSAGE_LOCAL_STORAGE_KEY, '')
    localStorage.setItem(TITLE_LOCAL_STORAGE_KEY, '')
    localStorage.setItem(SCREEN_LOCAL_STORAGE_KEY, '')
    localStorage.setItem(BODY_LOCAL_STORAGE_KEY, '')
  }

  useEffect(() => {
    // Update session storage only if form values change
    const storedForm = JSON.parse(
      sessionStorage.getItem(`${FORM_SESSION_STORAGE_KEY}_${shiftId}`) || '{}',
    )
    if (JSON.stringify(storedForm) !== JSON.stringify(form)) {
      sessionStorage.setItem(
        `${FORM_SESSION_STORAGE_KEY}_${shiftId}`,
        JSON.stringify(form),
      )
    }
  }, [form, shiftId])

  return {
    form,
    setForm,
    isFetchingWorkers,
    error,
    workersSearchResult,
    totalFound,
    reset,
    roleAttributes,
    isFetched,
    isLoadingAttributes,
    regions,
    isLoadingRegions,
    hotSettings,
    isLoadingHotSettings,
    handleClearParam,
    handleRegionSelect,
    handleSearch,
    currentPage,
    isFormValid,
    hasProcessedEligibility,
    setHasProcessedEligibility,
    handleClear,
  }
}

export default useBugleForm
