import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { CompletedAdjustments } from '@traba/types'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getQueryParams } from 'src/hooks/useApi'
import { TimesheetAdjustment } from '../timesheet.types'
import { buildErrorMessage } from '../utils'
import { TimesheetAdjustmentForm } from './TimesheetAdjustmentForm'

type TimesheetAdjustModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShiftAdjustments: TimesheetAdjustment[]
  prevTotalPay: number
  prevMinutesWorked: number
  newTotalPay: number
  newMinutesWorked: number
  onAdjust: () => void
}

export default function TimesheetAdjustModal({
  workerShiftAdjustments,
  isOpen,
  handleClose,
  prevTotalPay,
  prevMinutesWorked,
  newTotalPay,
  newMinutesWorked,
  onAdjust,
}: TimesheetAdjustModalProps) {
  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()

  const CHUNK_SIZE = 100

  const handleAdjustments = async ({
    shouldAdjustPayment,
    shouldInstantPay,
    workerShiftAdjustments,
  }: {
    shouldAdjustPayment: boolean
    shouldInstantPay: boolean
    workerShiftAdjustments: TimesheetAdjustment[]
  }) => {
    const queryString = getQueryParams([
      ['shouldAdjustPayment', shouldAdjustPayment.toString()],
      ['shouldInstantPay', shouldInstantPay.toString()],
    ])
    try {
      const chunkedAdjustments: TimesheetAdjustment[][] = []
      for (let i = 0; i < workerShiftAdjustments.length; i += CHUNK_SIZE) {
        chunkedAdjustments.push(workerShiftAdjustments.slice(i, i + CHUNK_SIZE))
      }

      const results = await Promise.allSettled(
        chunkedAdjustments.map((adjustments) =>
          trabaApi.post<CompletedAdjustments>(
            `worker-shifts/bulk-adjustments${
              shouldAdjustPayment ? `${queryString}` : ''
            }`,
            adjustments,
          ),
        ),
      )

      const { succeededWorkerIds, failedWorkerIdsAndReasons } =
        results.reduce<CompletedAdjustments>(
          (acc, result, index) => {
            if (result.status === 'fulfilled') {
              const { succeededWorkerIds, failedWorkerIdsAndReasons } =
                result.value.data
              acc.succeededWorkerIds.push(...succeededWorkerIds)
              acc.failedWorkerIdsAndReasons.push(...failedWorkerIdsAndReasons)
            } else {
              acc.failedWorkerIdsAndReasons.push(
                ...chunkedAdjustments[index].map<[string, string]>(
                  (adjustment) => [adjustment.workerId, `${result.reason}`],
                ),
              )
            }

            return acc
          },
          { succeededWorkerIds: [], failedWorkerIdsAndReasons: [] },
        )

      if (failedWorkerIdsAndReasons.length > 0) {
        const errorMessage = buildErrorMessage(failedWorkerIdsAndReasons)
        showError(
          errorMessage,
          `Error Adjusting ${failedWorkerIdsAndReasons.length} Workers. ${
            succeededWorkerIds.length
              ? `${succeededWorkerIds.length} Succeeded`
              : ''
          }`,
          Infinity,
        )
      } else {
        showSuccess(`${succeededWorkerIds.length} WorkerShifts Updated`)
      }
      onAdjust()
    } catch (error) {
      console.error('call to bulk adjust failed with error:', error)
      showError(
        'Error Adjusting Workers, check the console for more information',
      )
    } finally {
      refetchActiveQueries()
      handleClose()
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Make Adjustment for ${workerShiftAdjustments.length} Worker${
        workerShiftAdjustments.length > 1 ? 's' : ''
      }`}
      size={MODAL_SIZE.LARGE}
    >
      <TimesheetAdjustmentForm
        handleClose={handleClose}
        workerShiftAdjustments={workerShiftAdjustments}
        handleAdjustments={handleAdjustments}
        prevTotalPay={prevTotalPay}
        prevMinutesWorked={prevMinutesWorked}
        newTotalPay={newTotalPay}
        newMinutesWorked={newMinutesWorked}
      />
    </Modal>
  )
}
