import { AdjustmentLabel } from '@traba/types'

export enum WardenExemptionType {
  // Hard Rules
  PAYMENT_AMOUNT_BYPASS = 'PAYMENT_AMOUNT_BYPASS',
  EXPECTED_VS_ACTUAL_PAYMENT_BYPASS = 'EXPECTED_VS_ACTUAL_PAYMENT_BYPASS',
  DUPLICATE_DEVICE_BYPASS = 'DUPLICATE_DEVICE_BYPASS',
  // Model Exemptions
  OPS_CLOCKOUT_BYPASS = 'OPS_CLOCKOUT_BYPASS',
  KIOSK_CLOCKOUT_BYPASS = 'KIOSK_CLOCKOUT_BYPASS',
  MODEL_INFERENCE_BYPASS = 'MODEL_INFERENCE_BYPASS',
}

export enum WardenHardRule {
  PAYMENT_AMOUNT = 'PAYMENT_AMOUNT',
  EXPECTED_VS_ACTUAL_PAYMENT = 'EXPECTED_VS_ACTUAL_PAYMENT',
  DUPLICATE_DEVICE = 'DUPLICATE_DEVICE',
  BELOW_MIN_TIME_WORKED = 'BELOW_MIN_TIME_WORKED',
  WORKER_EDIT_TIME_FLAGGED = 'WORKER_EDIT_TIME_FLAGGED',
}

export type ExtendedWardenExemption = {
  id: string
  exemptionType: WardenExemptionType
  shiftId?: string
  workerId?: string
  worker?: {
    firstName: string
    lastName: string
  }
  companyId?: string
  company?: {
    employerName: string
  }
  roleId?: string
  role?: {
    roleName: string
  }
}

export type CreateWardenExemption = {
  exemptionType: WardenExemptionType
  shiftId?: string
  workerId?: string
  companyId?: string
  roleId?: string
}

export const wardenExemptionsInfoMap: Record<
  WardenExemptionType,
  { title: string; description: string }
> = {
  [WardenExemptionType.PAYMENT_AMOUNT_BYPASS]: {
    title: 'Payment Amount',
    description:
      'If the payment amount exemption is present on a worker shift, it wont be flagged by the payment amount hard rule.',
  },
  [WardenExemptionType.EXPECTED_VS_ACTUAL_PAYMENT_BYPASS]: {
    title: 'Expected vs Actual Payment',
    description:
      'If the expected vs actual payment exemption is present on a worker shift, it wont be flagged by the expected vs actual payment hard rule.',
  },
  [WardenExemptionType.DUPLICATE_DEVICE_BYPASS]: {
    title: 'Duplicate Device',
    description:
      'If the duplicate device exemption is present on a worker shift, it wont be flagged by the duplicate device hard rule.',
  },
  [WardenExemptionType.MODEL_INFERENCE_BYPASS]: {
    title: 'Model Inference',
    description:
      'If the model inference exemption is present on a worker shift, it will skip model inference and process payment assuming no hard rules are violated.',
  },
  [WardenExemptionType.OPS_CLOCKOUT_BYPASS]: {
    title: 'Ops Clockout',
    description:
      'If the ops clockout exemption is present on a worker shift and the worker clocked out via ops, it will skip model inference and process payment assuming no hard rules are violated.',
  },
  [WardenExemptionType.KIOSK_CLOCKOUT_BYPASS]: {
    title: 'Kiosk Clockout',
    description:
      'If the kiosk clockout exemption is present on a worker shift and the worker clocked out via kiosk, it will skip model inference and process payment assuming no hard rules are violated.',
  },
}

export const wardenHardRulesInfoMap: Record<
  WardenHardRule,
  { title: string; description: string; bypassable: boolean }
> = {
  [WardenHardRule.PAYMENT_AMOUNT]: {
    title: 'Payment Amount',
    description:
      'Broken if the net pay amount is > $300 for an instant pay or $500 for standard pay.',
    bypassable: true,
  },
  [WardenHardRule.EXPECTED_VS_ACTUAL_PAYMENT]: {
    title: 'Expected vs Actual Payment',
    description:
      'Broken if the actual gross payment amount is 50% greater than expected gross payment amount.',
    bypassable: true,
  },
  [WardenHardRule.DUPLICATE_DEVICE]: {
    title: 'Duplicate Device',
    description:
      'Broken if there exists another worker on the shift with the same deviceId.',
    bypassable: true,
  },
  [WardenHardRule.BELOW_MIN_TIME_WORKED]: {
    title: 'Below Min Time Worked',
    description:
      'Broken if the worker shifts timeWorked is less than the shifts min paid time.',
    bypassable: false,
  },
  [WardenHardRule.WORKER_EDIT_TIME_FLAGGED]: {
    title: 'Worker edit time flagged',
    description:
      'Broken if the edited times by the worker results in more paid time.',
    bypassable: false,
  },
}

export enum WardenSource {
  MODEL = 'MODEL',
  RULES_ENGING = 'RULES_ENGINE',
}

export type WardenResult = {
  id: string
  workerShiftId: string
  shouldProcessPayment: boolean
  source: WardenSource
  modelResult?: boolean
  modelProbability?: number
  adjustmentLabel?: AdjustmentLabel
  hardRuleViolations: WardenHardRule[]
  exemptionsApplied: WardenExemptionType[]
}
