import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Dispatch, SetStateAction } from 'react'
import { Button, Icon, IconButton, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { DotText } from 'src/components/base/DotText'
import { Dropdown } from 'src/components/base/Dropdown'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import {
  WorkerVettingSessionResponse,
  useWorkerVettingSession,
} from 'src/hooks/useWorkerVetting'
import { AvatarWrapper } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/styles'
import { formatPhoneNumber } from 'src/utils/stringUtils'
import { WorkerVettingMessageItem } from './WorkerVettingMessageItem'

interface WorkerVettingSessionMessageViewerProps {
  vettingSession: WorkerVettingSessionResponse
  setSelectedSession: Dispatch<
    SetStateAction<WorkerVettingSessionResponse | undefined>
  >
}

export default function WorkerVettingSessionMessageViewer(
  props: WorkerVettingSessionMessageViewerProps,
) {
  const { vettingSession, setSelectedSession } = props
  const { session, isLoading, patchSession } = useWorkerVettingSession(
    vettingSession.id,
  )
  const tz = useTimezonedDates()

  if (isLoading) {
    return (
      <Row center alignCenter>
        <CircularProgress
          size={32}
          sx={{
            position: 'absolute',
            top: '200px',
            left: '50%',
            marginTop: '-18px',
            marginLeft: '-18px',
          }}
        />
      </Row>
    )
  }

  if (!session) {
    return (
      <Row>
        <Text variant="body1">No session found</Text>
      </Row>
    )
  }

  const messages = session.messages

  //Want to have an icon for qualified, disqualified, and other
  const ReasonIcon = () => {
    if (vettingSession.score === 200) {
      return (
        <Row>
          <Icon name="greenCheck" />
        </Row>
      )
    }
    if (vettingSession.score === 500) {
      return <span role="img">❌</span>
    }
    return (
      <Row>
        <Icon name="caution" />
      </Row>
    )
  }

  return (
    <div
      style={{
        alignItems: 'center',
        borderLeft: `1px solid ${theme.colors.Grey30}`,
        borderBottom: `1px solid ${theme.colors.Grey30}`,
        width: '60%',
        marginLeft: theme.space.xs,
        paddingLeft: theme.space.xs,
        overflow: 'auto',
      }}
    >
      <Row justifyBetween>
        <IconButton
          iconName="chevronLeft"
          style={{ padding: theme.space.xs }}
          onClick={() => setSelectedSession(undefined)}
        />
        <Dropdown
          dropdownOptions={[
            {
              label: 'Good Match',
              handleClick: () => patchSession(vettingSession.id, 'qualify'),
              icon: () => <Icon name="checkmark" />,
            },
            {
              label: 'Bad Match',
              handleClick: () => patchSession(vettingSession.id, 'disqualify'),
              icon: () => <Icon name="cancel" />,
            },
            {
              label: 'Cancel',
              handleClick: () => patchSession(vettingSession.id, 'cancel'),
              icon: () => <Icon name="close" />,
            },
          ]}
          orientation="left"
        />
      </Row>

      <Row flexCol mt={theme.space.xs} mb={theme.space.xs}>
        {session.worker ? (
          <Row justifyBetween>
            <Row alignCenter>
              <AvatarWrapper style={{ marginRight: theme.space.xxs }}>
                <Avatar
                  alt={`worker_${session.worker.id}_avatar`}
                  src={session.worker.photoUrl}
                  sizes="40px"
                />
              </AvatarWrapper>
              <Link to={`/workers/${session.worker.id}`}>
                <Button variant={ButtonVariant.TEXT} style={{ padding: 0 }}>
                  {session.worker.firstName} {session.worker.lastName}
                </Button>
              </Link>
            </Row>
            <Text variant="caption">
              Last active at: {tz.getDateTime(vettingSession.lastActiveAt)}
            </Text>
          </Row>
        ) : (
          <Row alignCenter>
            <Text variant="h6">External Worker</Text>
            <DotText />
            <Text variant="h6">
              {formatPhoneNumber(vettingSession.phoneNumber, true)}
            </Text>
          </Row>
        )}
        <Row mt={theme.space.xxs} alignCenter>
          {session.opsScore !== null && (
            <Text variant="h6" style={{ marginRight: theme.space.xxs }}>
              [OPS SCORED]
            </Text>
          )}
          <ReasonIcon />
          <Text variant="body1" style={{ marginLeft: theme.space.xxs }}>
            Reason:{' '}
            {vettingSession.messages.length > 0
              ? vettingSession.messages[0].reviewReason
              : 'None'}
          </Text>
        </Row>
        <Text variant="caption">
          Last evaluated: {tz.getDateTime(vettingSession.updatedAt)}
        </Text>
      </Row>
      <Divider />
      <div
        style={{
          overflow: 'scroll',
          width: '100%',
          flexGrow: 1,
          overflowY: 'scroll',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Row center alignCenter mt={theme.space.xxs}>
          <Text variant="body2">- Beginning of conversation -</Text>
        </Row>
        {messages.map((message) => (
          <WorkerVettingMessageItem
            key={message.id}
            workerVettingMessage={message}
            pictureUrl={session.worker?.photoUrl}
          />
        ))}
      </div>
    </div>
  )
}
