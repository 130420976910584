import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  COMPANY_CONNECTIONS_QUERY_KEY,
  COMPANY_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
  ELIGIBILITY_CONNECTIONS_QUERY_KEY,
  WORKER_CONNECTIONS_QUERY_KEY,
  WORKER_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
} from '@traba/hooks'
import {
  Company,
  CompanyConnectionResponse,
  ConnectionType,
  Worker,
  WorkerConnectionResponse,
} from '@traba/types'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'

async function getWorkerConnections(
  workerId: string,
): Promise<WorkerConnectionResponse[] | undefined> {
  const res = await trabaApi.get(`workers/${workerId}/connections`)
  return res.data
}

async function getWorkerEligibilityConnections(
  workerId: string,
): Promise<Company[] | undefined> {
  try {
    const res = await trabaApi.get(
      `workers/${workerId}/eligibility-connections`,
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    console.error(
      'useWorkerEligibilityConnections -> getWorkerEligibilityConnections() ERROR',
      error,
    )
    Sentry.captureException(
      `useWorkerEligibilityConnections ERROR: ${errorMessage}`,
    )
  }
}

export async function deleteConnection(workerId: string, companyId: string) {
  const queryString = getQueryParams([
    ['workerId', workerId],
    ['bypassRestrictions', true],
  ])
  const res = await trabaApi.delete(
    `connections/company/${companyId}${queryString}`,
  )
  return res.data
}

export function useWorkerConnections(workerId: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerConnectionResponse[] | undefined, Error>({
    queryKey: [WORKER_CONNECTIONS_QUERY_KEY, workerId],
    queryFn: () => getWorkerConnections(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useWorkerConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    Sentry.captureException(fullErrorMessage)
    showError(fullErrorMessage, 'Error Fetching Worker Connections')
  }

  return {
    isLoading,
    isError,
    connections,
    error,
    isFetched,
    refetch,
  }
}

export function useWorkerEligibilityConnections(workerId: string) {
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<Company[] | undefined, Error>({
    queryKey: [
      ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      WORKER_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      workerId,
    ],
    queryFn: () => getWorkerEligibilityConnections(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    connections,
    error,
    isFetched,
    refetch,
  }
}

async function getCompanyConnections(
  companyId?: string,
): Promise<CompanyConnectionResponse[] | undefined> {
  if (!companyId) {
    return []
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}/connections`)
    return res.data
  } catch (error: any) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useCompanyConnections(companyId?: string) {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: connections,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyConnectionResponse[] | undefined, Error>({
    queryKey: [COMPANY_CONNECTIONS_QUERY_KEY, companyId],
    queryFn: () => getCompanyConnections(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    console.error(fullErrorMessage)
    Sentry.captureException(fullErrorMessage)
    showError(fullErrorMessage, 'Error Fetching Company Connections')
  }

  const favoriteWorkers =
    connections
      ?.filter((c) => c.connectionType === ConnectionType.FAVORITE)
      .map((c) => c.worker) ?? []

  return {
    isLoading,
    isError,
    connections,
    favoriteWorkers,
    error,
    isFetched,
    refetch,
  }
}

async function getCompanyEligibilityConnections(
  companyId: string,
): Promise<Worker[] | undefined> {
  try {
    const res = await trabaApi.get(
      `companies/${companyId}/eligibility-connections`,
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    console.error(
      'useCompanyEligibilityConnections -> getCompanyEligibilityConnections() ERROR',
      error,
    )
    Sentry.captureException(
      `useCompanyEligibilityConnections ERROR: ${errorMessage}`,
    )
  }
}

export function useCompanyEligibilityConnections(companyId: string) {
  const {
    isLoading,
    isError,
    data: eligibilityConnections,
    error,
    isFetched,
    refetch,
  } = useQuery<Worker[] | undefined, Error>({
    queryKey: [
      ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      COMPANY_ELIGIBILITY_CONNECTIONS_QUERY_KEY,
      companyId,
    ],
    queryFn: () => getCompanyEligibilityConnections(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  return {
    isLoading,
    isError,
    eligibilityConnections,
    error,
    isFetched,
    refetch,
  }
}
