import { Button, ButtonVariant, Row, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useVettingCampaignMutations } from 'src/hooks/useVettingCampaigns'
import { ConfirmVettingCampaignSyncModal } from 'src/modals/ConfirmVettingCampaignSyncModal/ConfirmVettingCampaignSyncModal'

export const VettingCampaignActions = ({
  campaignId,
  disabled = false,
}: {
  campaignId: string
  disabled?: boolean
}) => {
  const { syncVettingCampaign, isSyncPending } = useVettingCampaignMutations()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const handleSyncClick = () => {
    setIsConfirmModalOpen(true)
  }
  return (
    <>
      <Row
        justifyEnd
        alignCenter
        gap={theme.space.xsmed}
        mt={theme.space.xxxs}
        mb={theme.space.xxxs}
        style={{ width: '100%' }}
      >
        <Button
          leftIcon={
            <SvgIcon
              name="refresh"
              color={disabled ? theme.colors.Grey40 : theme.colors.Violet}
              style={{ transform: 'scaleX(-1)' }}
            />
          }
          style={{
            width: '86px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '16px',
          }}
          loading={isSyncPending}
          variant={ButtonVariant.BRANDNEW}
          onClick={handleSyncClick}
          disabled={disabled}
        >
          Sync
        </Button>
      </Row>
      <ConfirmVettingCampaignSyncModal
        isOpen={isConfirmModalOpen}
        handleClose={() => setIsConfirmModalOpen(false)}
        onSync={() => syncVettingCampaign(campaignId)}
      />
    </>
  )
}
