import { InfoTooltip } from '@traba/react-components'
import {
  AdjudicationStatus,
  BackgroundCheckAssessment,
  BackgroundCheckStatus,
  DetailedBackgroundCheckStatus,
} from '@traba/types'
import { calculateDetailedBackgroundCheckStatus } from 'src/utils/backgroundCheckUtils'
import Badge from '.'

export type BackgroundCheckStatusBadgeProps = {
  status: BackgroundCheckStatus
  adjudication?: AdjudicationStatus
  assessment?: BackgroundCheckAssessment
}

type StatusBadgeConfig = {
  variant: string
  title: string
}

const bgcStatusToBadgeConfig: Record<
  DetailedBackgroundCheckStatus,
  StatusBadgeConfig
> = {
  [DetailedBackgroundCheckStatus.PENDING]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.SUSPENDED]: {
    variant: 'danger',
    title: 'Suspended',
  },
  [DetailedBackgroundCheckStatus.CANCELED]: {
    variant: 'danger',
    title: 'Canceled',
  },
  [DetailedBackgroundCheckStatus.INVITATION_CREATED]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.DISPUTE]: {
    variant: 'warning',
    title: 'Dispute',
  },
  [DetailedBackgroundCheckStatus.CONSIDER]: {
    variant: 'warning',
    title: 'Consider',
  },
  [DetailedBackgroundCheckStatus.REPORT_CREATED]: {
    variant: 'info',
    title: 'Started',
  },
  [DetailedBackgroundCheckStatus.CLEAR]: { variant: 'success', title: 'Clear' },
  [DetailedBackgroundCheckStatus.COMPLETE]: {
    variant: 'success',
    title: 'Complete',
  },
  [DetailedBackgroundCheckStatus.POST_ADVERSE_ACTION]: {
    variant: 'danger',
    title: 'Post-adverse action',
  },
  [DetailedBackgroundCheckStatus.PRE_ADVERSE_ACTION]: {
    variant: 'warning',
    title: 'Pre-adverse action',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE]: {
    variant: 'success',
    title: 'Eligible',
  },
  [DetailedBackgroundCheckStatus.REVIEW]: {
    variant: 'warning',
    title: 'Review',
  },
  [DetailedBackgroundCheckStatus.ESCALATED]: {
    variant: 'danger',
    title: 'Escalated',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW]: {
    variant: 'success',
    title: 'Eligible After Review',
  },
}

export default function BackgroundCheckStatusBadge({
  status,
  adjudication,
  assessment,
}: BackgroundCheckStatusBadgeProps) {
  const detailedStatus = calculateDetailedBackgroundCheckStatus(
    status,
    adjudication,
    assessment,
  )
  const badgeConfig = bgcStatusToBadgeConfig[detailedStatus] ?? {
    variant: 'info',
    title: 'Unknown',
  }
  const tooltip =
    status === BackgroundCheckStatus.PENDING ||
    status === BackgroundCheckStatus.INVITATION_CREATED
      ? status
      : undefined

  return (
    <>
      <Badge title={badgeConfig.title} variant={badgeConfig.variant} />
      {tooltip && <InfoTooltip title={tooltip} />}
    </>
  )
}
