import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AlertContextProvider } from '@traba/context'
import { FirebaseProvider, firebaseConfig } from '@traba/firebase-utils'
import { theme } from '@traba/theme'
import { ConfigProvider } from 'antd'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'
import './index.css'
import {
  QueryClient as QueryClientV3,
  QueryClientProvider as QueryClientProviderV3,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { FirebaseAppProvider } from 'reactfire'
import App from './App'
import { SentinelContextProvider } from './context/sentinelContext/SentinelContext'
import { SettingsContextProvider } from './context/settingsContext/SettingsContext'
import UserContextProvider from './context/user/UserContext'
import { WebSocketProvider } from './context/webSocketContext/WebSocketContext'
import reportWebVitals from './reportWebVitals'

const applicationId = import.meta.env.VITE_DD_APPLICATION_ID
const clientToken = import.meta.env.VITE_DD_CLIENT_TOKEN
const ENVIRONMENTS_WITH_METICULOUS = ['development', 'staging']

async function startApp() {
  if (ENVIRONMENTS_WITH_METICULOUS.includes(import.meta.env.VITE_ENV)) {
    // Note from Meticulous docs: all errors are caught and logged, so no need to surround with try/catch
    await tryLoadAndStartRecorder({
      projectId: import.meta.env.VITE_METICULOUS_PROJECT_ID,
      isProduction: false,
    })
  }

  const queryClientV3 = new QueryClientV3()
  const queryClient = new QueryClient()
  const muiTheme = createTheme(theme as any)
  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Root element not found')
  }
  const root = createRoot(container)

  if (import.meta.env.VITE_SENTRY_DSN && !window.Meticulous?.isRunningAsTest) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      environment: import.meta.env.VITE_ENV,
    })
  }

  if (applicationId && clientToken && !window.Meticulous?.isRunningAsTest) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'us5.datadoghq.com',
      service: 'traba-ops-console',
      env:
        import.meta.env.VITE_ENV === 'production'
          ? 'production'
          : 'development',
      // Identifies the unique deploy version
      version: import.meta.env.VITE_DEPLOY_VERSION ?? 'local',
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
    })
    datadogRum.startSessionReplayRecording()
  }

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID

  if (!clientId) {
    throw new Error('Google Client ID is not defined')
  }

  const router = createBrowserRouter([
    {
      path: '*',
      element: <App />,
    },
  ])

  root.render(
    <GoogleOAuthProvider clientId={clientId}>
      <QueryClientProviderV3 client={queryClientV3}>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider>
            <UserContextProvider>
              <SentinelContextProvider>
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                  <FirebaseProvider>
                    <AlertContextProvider>
                      <SettingsContextProvider>
                        <MuiThemeProvider theme={muiTheme}>
                          <SnackbarProvider
                            classes={{
                              containerRoot: 'z-alert',
                            }}
                            maxSnack={4}
                          >
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: theme.palette.primary.main,
                                  colorLink: theme.palette.primary.main,
                                },
                              }}
                            >
                              <RouterProvider router={router} />
                            </ConfigProvider>
                          </SnackbarProvider>
                        </MuiThemeProvider>
                      </SettingsContextProvider>
                    </AlertContextProvider>
                  </FirebaseProvider>
                </FirebaseAppProvider>
              </SentinelContextProvider>
            </UserContextProvider>
          </WebSocketProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </QueryClientProviderV3>
    </GoogleOAuthProvider>,
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

startApp()
