import { LinearProgress, List } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DEFAULT_PAGE_SIZE } from 'src/hooks/usePagination'
import { WorkerWithEligibility } from 'src/hooks/useWorkerShiftsEligibilities'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { WorkerListItem } from '../../../screens/ShiftDetailsScreen/components/SearchWorkers/WorkerListItem'
import { Row, Button } from '../../base'
import Pagination from '../../base/Pagination/Pagination'
import { CustomParams } from '../BugleDrawer'
import * as S from '../styles'
import WorkerActions from '../WorkerActions'

interface PreviewSectionProps {
  isFetchingWorkers: boolean
  isFetchingEligibility: boolean
  workersWithEligibility: WorkerWithEligibility[] | undefined
  workersSearchResult: PopulatedWorker[]
  totalFound?: number
  isFirstSectionComplete?: boolean
  isSecondSectionComplete?: boolean
  currentPage: number
  handleSearch: (type: 'initial' | 'previous' | 'next') => void
  paramsSet: CustomParams
  error?: any
  onAvatarClick: (worker: PopulatedWorker) => void
  pageSize?: number
  selectableList?: boolean
  selectedWorkerList?: PopulatedWorker[]
  setSelectedWorkerList?: React.Dispatch<
    React.SetStateAction<PopulatedWorker[]>
  >
}

const PreviewSection: React.FC<PreviewSectionProps> = ({
  isFetchingWorkers,
  isFetchingEligibility,
  workersWithEligibility,
  workersSearchResult,
  isFirstSectionComplete = true,
  isSecondSectionComplete = true,
  currentPage,
  handleSearch,
  error,
  paramsSet,
  onAvatarClick,
  totalFound,
  selectableList,
  selectedWorkerList,
  setSelectedWorkerList,
  pageSize,
}) => {
  const hasWorkersToDisplay =
    !isFetchingWorkers &&
    !isFetchingEligibility &&
    workersWithEligibility &&
    workersWithEligibility.length > 0

  const workersSelectable =
    selectableList && setSelectedWorkerList && selectedWorkerList

  const allSelected =
    selectedWorkerList &&
    hasWorkersToDisplay &&
    selectedWorkerList.length === workersWithEligibility.length

  const handleSelectAll = () => {
    if (workersSelectable) {
      if (allSelected) {
        setSelectedWorkerList([])
      } else {
        setSelectedWorkerList(
          workersWithEligibility?.map((w) => w.worker) || [],
        )
      }
    }
  }
  return (
    <div
      color={
        isSecondSectionComplete ? theme.colors.Green10 : theme.colors.Blue10
      }
    >
      {!isFirstSectionComplete && paramsSet === 'custom' && (
        <Text variant="error" ml={theme.space.xxs}>
          You need to fill out the Parameters section first!
        </Text>
      )}
      {error && (
        <Text variant="error">
          Error searching for workers. {JSON.stringify(error)}
        </Text>
      )}
      <S.WorkersListWrapper>
        {workersSelectable && (
          <Button
            slim
            onClick={handleSelectAll}
            style={{ marginBottom: theme.space.xs, marginTop: theme.space.xs }}
          >
            {allSelected ? 'Unselect' : 'Select'} All
          </Button>
        )}
        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
          {hasWorkersToDisplay &&
            workersWithEligibility.map((w, i) => (
              <WorkerListItem
                worker={w.worker}
                index={i}
                showIndex
                key={w.worker.workerId}
                actions={<WorkerActions data={w} />}
                onAvatarClick={() => onAvatarClick(w.worker)}
                {...(workersSelectable && {
                  handleSelection: (worker) => {
                    const workerExists = selectedWorkerList.find(
                      (w) => w.id === worker.id,
                    )
                    if (workerExists) {
                      setSelectedWorkerList(
                        selectedWorkerList.filter((w) => w.id !== worker.id),
                      )
                    } else {
                      setSelectedWorkerList([...selectedWorkerList, w.worker])
                    }
                  },
                  workerIsSelected: selectedWorkerList.includes(w.worker),
                })}
              />
            ))}
        </List>
      </S.WorkersListWrapper>
      <Row justifyBetween alignEnd>
        <Row justifyStart alignEnd>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => handleSearch('initial')}
            disabled={!isFirstSectionComplete || isFetchingEligibility}
            loading={isFetchingWorkers}
          >
            {workersSearchResult.length ? 'Update List' : 'Generate List'}
          </Button>
        </Row>
        {!hasWorkersToDisplay &&
          !isFetchingWorkers &&
          !isFetchingEligibility &&
          !totalFound && <Text>No workers to show</Text>}

        {!!totalFound && !isFetchingWorkers && !isFetchingEligibility && (
          <Text>Total workers found: {totalFound}</Text>
        )}

        {isFetchingWorkers && (
          <Text mt={theme.space.med}>
            Fetching workers that match this list <LinearProgress />
          </Text>
        )}

        {!isFetchingWorkers && isFetchingEligibility && (
          <Text mt={theme.space.med}>
            Calculating eligibility for the workers found <LinearProgress />
          </Text>
        )}
        <Pagination
          dataSize={workersSearchResult.length}
          onPageLeft={() => handleSearch('previous')}
          onPageRight={() => handleSearch('next')}
          page={currentPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          disabled={isFetchingEligibility || isFetchingWorkers}
        />
      </Row>
    </div>
  )
}

export default PreviewSection
