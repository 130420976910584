import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  DotMenu,
  Text,
  UserAccessLevelOrLocationsStringWithTooltip,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyInvitation, USER_ROLE_NAMES } from '@traba/types'
import {
  assignedActiveLocationsForInvitation,
  isBizInvitationCompanyWide,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { UseMutateAsyncFunction } from 'react-query'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import {
  CreateInvitationParams,
  generateInvitationLink,
} from 'src/hooks/useCompanyInvitations'
import { truncateString } from 'src/utils/stringUtils'
import { CreateInvitationModal } from './CreateInvitationModal'

interface InvitationsTableProps {
  invitations: CompanyInvitation[]
  companyId: string
  createInvitationMutation: UseMutateAsyncFunction<
    CompanyInvitation,
    AxiosError<unknown, any>,
    CreateInvitationParams,
    unknown
  >
  resendInvitationMutation: UseMutateAsyncFunction<
    CompanyInvitation,
    AxiosError<unknown, any>,
    { companyId: string; invitationId: string },
    unknown
  >
  rescindInvitationMutation: UseMutateAsyncFunction<
    CompanyInvitation,
    AxiosError<unknown, any>,
    { companyId: string; invitationId: string },
    unknown
  >
}

export default function InvitationsTable({
  invitations,
  companyId,
  createInvitationMutation,
  resendInvitationMutation,
  rescindInvitationMutation,
}: InvitationsTableProps) {
  const { hotSettings } = useHotSettings()
  const { showSuccess, showError } = useAlert()
  const createInvitationModal = useModal()
  const fetchCopyText = async (companyId: string, invitationId: string) => {
    return await generateInvitationLink(companyId, invitationId)
  }

  function formatDate(date: Date | undefined): string {
    return date
      ? new Date(date).toLocaleDateString('en-us', {
          timeZone: 'America/New_York', // Using EST timezone for now
        })
      : 'N/A'
  }

  const invitationsHeaders: (DataTableHeader | string)[] = [
    {
      key: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      key: 'invitationId',
      label: 'Invitation ID',
      sortable: true,
    },
    {
      key: 'role',
      label: 'Role',
      sortable: true,
    },
    ...(hotSettings?.enableRegionalAccessPhase2
      ? [
          {
            key: 'locations',
            label: 'Locations',
          },
        ]
      : []),
    {
      key: 'sentAt',
      label: 'Sent At',
      sortable: true,
    },
    {
      key: 'expiresAt',
      label: 'Expires on',
      sortable: true,
    },
    {
      key: 'rescindedAt',
      label: 'Rescinded At',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'inviteLink',
      label: 'Invite link',
    },
    {
      label: '',
    },
  ]

  const invitationsRows: TableRow[] = invitations.map((invitation) => {
    const {
      invitationId,
      role,
      email,
      status,
      expiresAt,
      sentAt,
      rescindedAt,
    } = invitation

    const actionsDisabled = !(status === 'SENT' || status === 'PENDING')
    const copyLinkDisabled =
      !(status === 'SENT' || status === 'PENDING') ||
      new Date(expiresAt) < new Date()
    const invitationsCells: TableCell[] = [
      {
        sortKey: email,
        renderFn: () => email,
      },
      {
        sortKey: invitationId,
        renderFn: () => (
          <>
            {truncateString(invitationId)}
            <CopyTextIcon textToCopy={invitationId} />
          </>
        ),
      },
      {
        sortKey: role,
        renderFn: () => USER_ROLE_NAMES[role],
      },
      ...(hotSettings?.enableRegionalAccessPhase2
        ? [
            {
              renderFn: () => (
                <UserAccessLevelOrLocationsStringWithTooltip
                  isCompanyWide={isBizInvitationCompanyWide(invitation)}
                  assignedLocations={assignedActiveLocationsForInvitation(
                    invitation,
                  )}
                />
              ),
            },
          ]
        : []),
      {
        sortKey: sentAt ? new Date(sentAt).getTime() : 0,
        renderFn: () => formatDate(sentAt),
      },
      {
        sortKey: new Date(expiresAt).getTime(),
        renderFn: () => formatDate(expiresAt),
      },
      {
        sortKey: rescindedAt ? new Date(rescindedAt).getTime() : 0,
        renderFn: () => formatDate(rescindedAt),
      },
      {
        sortKey: 'status',
        renderFn: () => status,
      },
      {
        renderFn: () => (
          <Row
            style={{
              opacity: copyLinkDisabled ? 0.5 : 1,
            }}
          >
            Invite Link
            <CopyTextIcon
              fetchCopyText={() => {
                return fetchCopyText(companyId, invitationId)
              }}
              disabled={copyLinkDisabled}
            />
          </Row>
        ),
      },
      {
        renderFn: () => (
          <DotMenu
            type="invitations"
            dotMenuKey={invitationId}
            menuItems={[
              {
                title: 'Resend',
                onClick: async () => {
                  try {
                    const data = await resendInvitationMutation({
                      companyId,
                      invitationId,
                    })
                    showSuccess(
                      `The invitation email was successfully resent to ${data.email}`,
                      'Successfully resent invitation',
                    )
                  } catch (error) {
                    showError(
                      (error as Error).message ??
                        'Unknown error occurred during resending invitation.',
                      'Error resending invitation',
                    )
                  }
                },
                disabled: actionsDisabled,
              },
              {
                title: 'Rescind',
                onClick: async () => {
                  try {
                    const data = await rescindInvitationMutation({
                      companyId,
                      invitationId,
                    })
                    showSuccess(
                      `The invitation sent to ${data.email} was successfully rescinded`,
                      'Successfully rescinded invitation',
                    )
                  } catch (error) {
                    showError(
                      (error as Error).message ??
                        'Unknown error occurred during rescinding invitation.',
                      'Error rescinding invitation',
                    )
                  }
                },
                disabled: actionsDisabled,
              },
            ]}
          />
        ),
      },
    ]
    return {
      key: invitationId,
      cells: invitationsCells,
    }
  })

  return (
    <>
      <Row justifyBetween fullWidth mb={theme.space.sm} mt={theme.space.med}>
        <Text variant="h5" mt={theme.space.ms}>
          Invited Members
        </Text>
        <Button
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={createInvitationModal.open}
          style={{ padding: 0 }}
        >
          Create new invitation
        </Button>
      </Row>
      <DataTable
        headers={invitationsHeaders}
        rows={invitationsRows}
        initialSortByColumnIndex={3}
        initialSortByAscOrder={false}
      />
      <CreateInvitationModal
        isOpen={createInvitationModal.isOpen}
        onClose={createInvitationModal.handleClose}
        createInvitationMutation={createInvitationMutation}
        companyId={companyId}
      />
    </>
  )
}
