import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { WorkerDetailsForWatchlist } from '@traba/types'

async function getWorkersOnWatchlist(
  nextShiftStartTimeBefore: Date,
  showBgcIncomplete: boolean,
  showStripeIncomplete: boolean,
  workerSearchText: string,
  regionIds: string[],
  page: number,
  pageSize: number,
) {
  try {
    const res = await trabaApi.post('/workers/watchlist', {
      nextShiftStartTimeBefore,
      showBgcIncomplete,
      showStripeIncomplete,
      workerSearchText,
      regions: regionIds,
      limit: pageSize,
      offset: page * pageSize,
    })
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkersOnWatchlist() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    Sentry.captureException(error)
    console.error(errorMessage)
  }
}

export function useWorkersOnWatchlist(
  nextShiftStartTimeBefore: Date,
  showBgcIncomplete: boolean,
  showStripeIncomplete: boolean,
  workerSearchText: string,
  regionIds: string[],
  page: number,
  pageSize: number,
) {
  const queryKey = [
    'workersOnWatchlist',
    nextShiftStartTimeBefore,
    showBgcIncomplete,
    showStripeIncomplete,
    workerSearchText,
    regionIds,
    page,
    pageSize,
  ]

  const queryFn = () =>
    getWorkersOnWatchlist(
      nextShiftStartTimeBefore,
      showBgcIncomplete,
      showStripeIncomplete,
      workerSearchText,
      regionIds,
      page,
      pageSize,
    )

  const {
    isLoading,
    isFetching,
    isError,
    data: workersOnWatchlist,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerDetailsForWatchlist[], Error>({
    queryKey,
    queryFn,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isFetching,
    isError,
    workersOnWatchlist,
    error,
    isFetched,
    refetch,
  }
}
