import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Worker } from '@traba/types'
import { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { WorkerVettingSessionStatus } from 'src/types/worker-vetting'
import {
  WorkerVettingCampaign,
  WorkerVettingMethod,
  WorkerVettingSession,
  WorkerVettingSessionMessage,
  SupportedLanguages,
} from '../types/worker-vetting'
import {
  PaginationParams,
  useBasicPagination,
  basePaginationParams,
  DEFAULT_PAGE_SIZE,
} from './usePagination'
import { makeSearchParameters } from './utils/searchUtils'

export type CreateWorkerVettingCampaignData = {
  title: string
  initialMessage: string
  promptDetails: string
  phoneNumbers: string
  shiftId: string
  questions: string[]
  regionIds: string[]
  deadline: Date
  type: WorkerVettingMethod
  language: SupportedLanguages
  customQualifiedExitMessage?: string
  customUnqualifiedExitMessage?: string
}

export type WorkerVettingSessionResponse = WorkerVettingSession & {
  messages: WorkerVettingSessionMessage[]
  worker?: Worker
}

export type WorkerVettingCampaignResponse = WorkerVettingCampaign & {
  workerVettingSessions: WorkerVettingSessionResponse[]
}

/** Remove letters and trim spaces */
function parsePhoneNumbers(phoneNumbersString: string): string[] {
  return phoneNumbersString
    .split(',')
    .map((number) => {
      return number.trim().replace(/(?!^\+)\D/g, '')
    })
    .filter((number) => number !== '')
}

export type WorkerVettingCampaignParams = {
  id?: string
  shiftId?: string
  roleId?: string
  title?: string
  phoneNumbers?: string[]
  regionIds?: string[]
  initiatedBy?: string
}

export type WorkerVettingCampaignQuery = {
  withCount?: boolean
  parameters: WorkerVettingCampaignParams
}

export async function runSearchWorkerVettingCampaigns(
  parameters: WorkerVettingCampaignParams,
  paginationParams: PaginationParams,
  config?: AxiosRequestConfig,
): Promise<
  | { workerVettingCampaigns: WorkerVettingCampaignResponse[]; count: number }
  | undefined
> {
  try {
    const payload: WorkerVettingCampaignQuery = {
      parameters: makeSearchParameters(parameters),
      withCount: true,
    }
    const { limit, offset, sortBy, sortOrder } = paginationParams
    const URL = `workers/vetting/search?startAt=${offset}&limit=${limit}${
      sortBy ? `&orderBy=${String(sortBy)}` : ''
    }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
    const res = await trabaApi.post(URL, payload, config)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

const SEARCH_VETTING_QUERY_KEY = 'vettingSearchCacheKey'

export function useSearchWorkerVettingCampaigns({
  params,
  paginationParams,
  config,
}: {
  params: WorkerVettingCampaignParams
  paginationParams: PaginationParams
  config?: AxiosRequestConfig
}) {
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  const {
    data: searchResults,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [SEARCH_VETTING_QUERY_KEY, params, currentPage],
    () => runSearchWorkerVettingCampaigns(params, pagination, config),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  )

  return {
    campaigns: searchResults?.workerVettingCampaigns || [],
    totalFound: searchResults?.count || 0,
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}

export function useWorkerVetting() {
  const [isLoadingVettingData, setIsLoadingVettingData] = useState(false)
  const { showError, showSuccess } = useAlert()

  async function createNewWorkerVettingCampaign(
    campaignData: CreateWorkerVettingCampaignData,
  ) {
    try {
      setIsLoadingVettingData(true)
      const response = await trabaApi.post('workers/vetting/start-vetting', {
        ...campaignData,
        phoneNumbers: parsePhoneNumbers(campaignData.phoneNumbers),
      })
      showSuccess('Vetting campaign created successfully', '')
      return response.data
    } catch (error: any) {
      showError(
        `There was an error creating the vetting campaign: ${error.message}`,
        'Error',
      )
      Sentry.captureException(error)
    } finally {
      setIsLoadingVettingData(false)
    }
  }

  return { isLoadingVettingData, createNewWorkerVettingCampaign }
}

const getWorkerVettingCampaign = async (
  campaignId: string,
): Promise<WorkerVettingCampaignResponse | undefined> => {
  try {
    const response = await trabaApi.get(
      `/workers/vetting/campaign/${campaignId}?includeMessages=true`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

const getWorkerVettingSession = async (
  sessionId: string,
): Promise<WorkerVettingSessionResponse | undefined> => {
  try {
    const response = await trabaApi.get(
      `/workers/vetting/session/${sessionId}?includeMessages=true&includeWorker=true`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerVettingCampaign = (campaignId: string) => {
  const { showError, showSuccess } = useAlert()

  const {
    isLoading,
    isError,
    data: campaign,
    error,
    isFetching,
    refetch,
  } = useQuery(`workerVettingCampaign_${campaignId}`, () =>
    getWorkerVettingCampaign(campaignId),
  )

  const cancelWorkerVettingCampaign = async (campaignId: string) => {
    if (!window.confirm(`Are you sure you want to cancel this campaign?`)) {
      return
    }
    try {
      await trabaApi.patch(`/workers/vetting/cancel-campaign/${campaignId}`)
      showSuccess('Vetting campaign cancelled successfully', '')
    } catch (error: any) {
      showError(
        error.message,
        'There was an error cancelling the vetting campaign',
      )
      Sentry.captureException(error)
    }
  }

  return {
    isLoading,
    isError,
    campaign,
    error,
    isFetching,
    refetch,
    cancelWorkerVettingCampaign,
  }
}

export const useWorkerVettingSession = (sessionId: string) => {
  const { showError, showSuccess } = useAlert()

  const {
    isLoading,
    isError,
    data: session,
    error,
    isFetching,
    refetch,
  } = useQuery(`workerVettingSession_${sessionId}`, () =>
    getWorkerVettingSession(sessionId),
  )

  const patchSession = async (
    sessionId: string,
    action: 'qualify' | 'disqualify' | 'cancel',
  ) => {
    if (!window.confirm(`Are you sure you want to ${action} this session?`)) {
      return
    }
    try {
      let reqBody = {}
      if (action === 'qualify') {
        reqBody = { opsScore: 200 }
      } else if (action === 'disqualify') {
        reqBody = { opsScore: 500 }
      } else if (action === 'cancel') {
        reqBody = { status: WorkerVettingSessionStatus.canceled }
      }
      await trabaApi.patch(`/workers/vetting/session/${sessionId}`, reqBody)
      showSuccess('Vetting session updated successfully')
    } catch (error: any) {
      showError(
        error.message,
        'There was an error updating the vetting session',
      )
      Sentry.captureException(error)
    }
  }

  return {
    isLoading,
    isError,
    session,
    error,
    isFetching,
    refetch,
    patchSession,
  }
}
