import { Text, Col, InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { getFirstShiftDateError } from '@traba/utils'
import { addMinutes, differenceInMinutes, isBefore, subMinutes } from 'date-fns'
import { isUndefined } from 'lodash'
import React, { useState } from 'react'
import { Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'

interface ShiftTimePickerProps {
  createShiftRequest: CreateShiftRequest
  setCreateShiftRequest: React.Dispatch<
    React.SetStateAction<CreateShiftRequest>
  >
  businessStartTime: Date | null
  setBusinessStartTime: React.Dispatch<React.SetStateAction<Date | null>>
  timezone: string
  isInvalidBuffer: boolean
  setIsInvalidBuffer: React.Dispatch<React.SetStateAction<boolean>>
}

const ShiftTimePicker = ({
  createShiftRequest,
  setCreateShiftRequest,
  businessStartTime,
  setBusinessStartTime,
  timezone,
  setIsInvalidBuffer,
}: ShiftTimePickerProps) => {
  const [buffer, setBuffer] = useState<number>(0)

  const validateBuffer = () => {
    // Validate buffer value
    if (buffer < 0 || buffer > 120) {
      return setIsInvalidBuffer(true)
    }
    setIsInvalidBuffer(false)
  }

  const handleBufferUpdate = (newBuffer: number) => {
    if (isUndefined(newBuffer)) {
      return
    }

    validateBuffer()
    const currentStartTime =
      businessStartTime || new Date(createShiftRequest.schedules[0].startTime)
    // Assign business start time if it doesn't exist
    if (!businessStartTime) {
      setBusinessStartTime(currentStartTime)
    }
    // Use currentStartTime instead of businessStartTime to handle for rerender dependency when setting state above
    const newStartTime = subMinutes(currentStartTime.getTime(), buffer)
    handleStartTimeChange(newStartTime, true) // update startTime to adjust for the buffer
  }

  const handleStartTimeChange = (
    newDate: Date | null,
    isFourDigitYear?: boolean,
  ) => {
    if (newDate && isFourDigitYear) {
      let newEndTime = new Date(createShiftRequest.schedules[0].endTime)

      if (isBefore(createShiftRequest.schedules[0].endTime, newDate)) {
        const shiftLength = differenceInMinutes(
          createShiftRequest.schedules[0].endTime,
          createShiftRequest.schedules[0].startTime,
        )
        newEndTime = addMinutes(newDate, shiftLength)
      }

      // we need to update businessStartTime when the startTime changes and there is no buffer
      // if we update startTime when there is a buffer, we will be in a cycle.
      if (buffer === 0) {
        setBusinessStartTime(new Date(newDate))
      }

      // TODO(gavin): shift data model, build schedules array to accept 2 schedules eventually
      setCreateShiftRequest((prevShiftRequest) => ({
        ...prevShiftRequest,
        schedules: prevShiftRequest.schedules.map((schedule) => ({
          ...schedule,
          startTime: new Date(newDate),
          endTime: newEndTime,
        })),
      }))
    }
  }

  const handleEndTimeChange = (newDate: Date | null) => {
    // TODO(gavin): shift data model, build schedules array to accept 2 schedules eventually
    if (newDate) {
      setCreateShiftRequest((prevShiftRequest) => ({
        ...prevShiftRequest,
        schedule: {
          ...prevShiftRequest.schedules[0],
          endTime: newDate,
        },
        schedules: [
          {
            ...prevShiftRequest.schedules[0],
            endTime: newDate,
          },
        ],
      }))

      if (
        createShiftRequest.schedules[0].recurringSchedule?.endDate &&
        isBefore(
          createShiftRequest.schedules[0].recurringSchedule.endDate,
          newDate,
        )
      ) {
        setCreateShiftRequest((prevShiftRequest) => ({
          ...prevShiftRequest,
          schedule: {
            ...prevShiftRequest.schedules[0],
            recurringSchedule: {
              repeatOn:
                prevShiftRequest.schedules[0].recurringSchedule?.repeatOn || [],
              freq: 'WEEKLY',
              interval: 1,
              endDate: newDate,
            },
          },
          schedules: [
            {
              ...prevShiftRequest.schedules[0],
              recurringSchedule: {
                repeatOn:
                  prevShiftRequest.schedules[0].recurringSchedule?.repeatOn ||
                  [],
                freq: 'WEEKLY',
                interval: 1,
                endDate: newDate,
              },
            },
          ],
        }))
      }
    }
  }

  const handleBusinessStartTimeChange = (newDate: Date | null) => {
    // Business start time change logic
    if (!newDate) {
      return
    }

    setBusinessStartTime(newDate)
    handleStartTimeChange(new Date(newDate.getTime() - buffer * 60000), true)
  }

  const handleBufferChange = (newBuffer: number) => {
    // Buffer change logic
    setBuffer(newBuffer)
    handleBufferUpdate(newBuffer)
  }
  //cannot set a buffer for a shift that starts in less than 2 hours
  const twoHoursFromNow = new Date()
  twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)

  const fieldDisabled = isBefore(
    createShiftRequest.schedules[0].startTime,
    twoHoursFromNow,
  )
  const firstShiftDateError =
    getFirstShiftDateError(createShiftRequest)?.message

  return (
    <Row justifyBetween my={theme.space.sm} wrap>
      {/* Start Time Input Section */}
      <InfoTooltip
        title={
          'You can only edit the start time when an early arrival buffer is 0. Edit the buffer or the business start time if there is a buffer to modify this value.'
        }
      />
      <ShiftPostingInputContainerSection
        label={
          createShiftRequest.schedules[0].isRecurringSchedule
            ? 'First shift start time'
            : 'Start Time'
        }
        input={
          <Col>
            <DatePicker
              date={createShiftRequest.schedules[0].startTime}
              showTimeFieldInPopover={true}
              setDate={handleStartTimeChange}
              isClearable={false}
              timezone={timezone}
              aria-label="Start Time"
              isDisabled={!!buffer}
            />
            <Text variant="error">{firstShiftDateError}</Text>
          </Col>
        }
      />
      {/* Business Start Time Input Section */}
      {businessStartTime !== createShiftRequest.schedules[0].startTime &&
        buffer !== 0 &&
        businessStartTime && (
          <>
            <InfoTooltip
              title={
                'The business start time is what is shown to the business on the biz app. They do not see buffers or modified shift times.'
              }
            />
            <ShiftPostingInputContainerSection
              label="Business Start Time"
              input={
                <DatePicker
                  date={businessStartTime}
                  showTimeFieldInPopover={true}
                  setDate={handleBusinessStartTimeChange}
                  isClearable={false}
                  timezone={timezone}
                  aria-label="Start Time"
                />
              }
            />
          </>
        )}
      {/* Buffer Input Section */}
      <InfoTooltip
        title={
          'The early arrival buffer can only be <= 2 hours. You can only edit this value for start times <= 2 hours from now. Note that this amount of time is a cost to Traba (cost = buffer/60 * hourlyRate * workers)!'
        }
      />
      <ShiftPostingInputContainerSection
        label="Early Arrival Buffer"
        input={
          <Row alignCenter>
            <NumberInput
              value={buffer}
              setValue={(value) => {
                if (value) {
                  handleBufferChange(value)
                } else {
                  handleBufferChange(0)
                }
              }}
              min={0}
              max={120}
              step={1}
              placeholder={'e.g. 15'}
              onError={() => setIsInvalidBuffer(true)}
              disabled={fieldDisabled}
            />
          </Row>
        }
      />
      {/* End Time Input Section */}
      <ShiftPostingInputContainerSection
        label="End Time"
        input={
          <DatePicker
            date={createShiftRequest.schedules[0].endTime}
            showTimeFieldInPopover={true}
            setDate={handleEndTimeChange}
            isClearable={false}
            timezone={timezone}
            aria-label="End Time"
          />
        }
      />
    </Row>
  )
}

export default ShiftTimePicker
